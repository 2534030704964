import React, { useState,useCallback } from "react";
import { Link, useLocation, } from "react-router-dom";
import { useEffect } from "react";
import "./sidebar.css";
import homeActiveIcon from "./images/Menu_icons/home_white-01.png";
import homeInactiveIcon from "./images/Menu_icons/home_black-01.png";
import documentsActiveIcon from "./images/Menu_icons/My_documents_white-01.png";
import documnetsInactiveIcon from "./images/Menu_icons/My_documents_black-01.png";
import paymentsActiveIcon from "./images/Menu_icons/wallet_white-01.png";
import paymentsInactiveIcon from "./images/Menu_icons/wallet_black-01.png";
import passwordActiveIcon from "./images/Menu_icons/password_&_secuirty_white-01.png";
import passwordInactiveIcon from "./images/Menu_icons/password_&_secuirty_black-01.png";
import reviewActiveIcon from "./images/Menu_icons/Review_&_ratings_white-01.png";
import reviewInactiveIcon from "./images/Menu_icons/Review_&_ratings_black-01.png";
import servicesActiveIcon from "./images/Menu_icons/My_services_white-01.png";
import servicesInactiveIcon from "./images/Menu_icons/My_services_black.png";
import homeActiveMobileIcon from './images/home_Orange.png';
import servicesActiveMobileIcon from "./images/My_services_Orange.png";
import documentsActiveMobileIcon from './images/My_documents_Orange.png';
import transactionsActiveMobileIcon from './images/wallet_Orange.png';
import passwordActiveMobileIcon from './images/password_&_secuirty_Orange.png';
import reviewActiveMobileIcon from "./images/Review_&_ratings_Orange.png";
import { useTranslation } from 'react-i18next';
import { Wallet } from "./api/helper/urlhelper";
import { extractLoginUser } from "./api/utils/utils";
const Walleturl = Wallet();
const Sidebar = () => {
  const location = useLocation();
  const login_user = extractLoginUser();
  const [menuOpen, setMenuOpen] = useState(false);
  const [showSidebar, setShowSidebar] = useState(window.innerWidth >= 768);
  const [linkImages, setLinkImages] = useState({
    "/profile": { active: homeActiveIcon, inactive: homeInactiveIcon ,activeMobile: homeActiveMobileIcon,inactiveMobile: homeInactiveIcon, },
    [`/u/${login_user}/services`]: { active: servicesActiveIcon, inactive: servicesInactiveIcon ,activeMobile: servicesActiveMobileIcon,inactiveMobile: servicesInactiveIcon,},
    [`/u/${login_user}/documents`]: { active: documentsActiveIcon, inactive: documnetsInactiveIcon ,activeMobile: documentsActiveMobileIcon,inactiveMobile: documnetsInactiveIcon,},
    "https://wallet.dev.evzone.app": { active: paymentsActiveIcon, inactive: paymentsInactiveIcon ,activeMobile: transactionsActiveMobileIcon,inactiveMobile: paymentsInactiveIcon },
    [`/u/${login_user}/security`]: { active: passwordActiveIcon, inactive: passwordInactiveIcon,activeMobile: passwordActiveMobileIcon,inactiveMobile: passwordInactiveIcon },
    [`/u/${login_user}/reviews`] : { active: reviewActiveIcon, inactive: reviewInactiveIcon , activeMobile: reviewActiveMobileIcon,inactiveMobile: reviewInactiveIcon },
    [`/u/${login_user}/reviewratings`]: { active: reviewActiveIcon, inactive: reviewInactiveIcon , activeMobile : reviewActiveMobileIcon , inactiveMobile : reviewInactiveIcon }
  });

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };  

  const isLinkActive = useCallback((linkPath) => {
    return location.pathname === linkPath;
  }, [location]);

  
  // const getLinkIcon = (linkPath) => {
  //   const link = linkImages[linkPath];
  //   return isLinkActive(linkPath) ? (link?.active || homeActiveIcon) : (link?.inactive || homeInactiveIcon);
  // };
  const getLinkIcon = (linkPath) => {
    const link = linkImages[linkPath];
    const isActive = isLinkActive(linkPath);
  
    // if (window.innerWidth < 768) {
    //   return isActive ? (link?.activeMobile || link?.active || homeActiveMobileIcon) : (link?.inactiveMobile || link?.inactive || homeInactiveIcon);
    // }
    if (window.innerWidth < 1024) {
      return isActive ? (link?.activeMobile || link?.active || homeActiveMobileIcon) : (link?.inactiveMobile || link?.inactive || homeInactiveIcon);
    }
  
    return isActive ? (link?.active || homeActiveIcon) : (link?.inactive || homeInactiveIcon);
  };
  const handleResize = () => {
    if (window.innerWidth >= 768) {
      setShowSidebar(true);
    } else {
      setShowSidebar(menuOpen);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleWalletClick = async () => {
      // const storedToken = localStorage.getItem('access_token');
      // const valueToSend1 = storedToken;
      const subdomainURL = new URL(`https://wallet.dev.evzone.app/u/${login_user}`);
      // subdomainURL.searchParams.set('sharedValue', encodeURIComponent(valueToSend1));
      window.open(subdomainURL.toString(), '_blank');
  };
  const presonaldocument = () => {
    localStorage.setItem('activeNavItem', 'General Identification');
  }
  const {t} = useTranslation();

  return (
    <>
       
      <div className={`sidebar   ${menuOpen ? "open" : ""}`}>
        <div className="toggle-button" onClick={handleMenuToggle}>
          <i className={`fa ${menuOpen ? "fa-times" : "fa-bars"}`} />
        </div>
        <nav>
          <ul>
          {/* <li>
              <Link to="/profile" className={isLinkActive("/profile") ? "active" : "nonactive"}>

                <img
                  src={getLinkIcon("/profile")}
                  alt="Home"
                  className="menu-icon "
                />
                <span>{t("Profile")}</span>
              </Link>
            </li> */}
            <li>
              <Link to={`/u/${login_user}/services`} className={isLinkActive(`/u/${login_user}/services`) ? "active" : "nonactive"}>

                <img
                  src={getLinkIcon(`/u/${login_user}/services`)}
                  alt="services"
                  className="menu-icon "
                />
                <span>{t("Services Profile")}</span>
              </Link>
            </li>
            <li>
              <Link to={`/u/${login_user}/documents`} onClick={presonaldocument} className={isLinkActive(`/u/${login_user}/documents`) ? "active" : "nonactive"}>

                <img
                  src={getLinkIcon(`/u/${login_user}/documents`)}
                  alt="documents"
                  className="menu-icon "
                />
                <span onClick={presonaldocument}>{t("Personal Documentation")}</span>
              </Link>
            </li>
            <li>
              <Link to={``} onClick={handleWalletClick} className={isLinkActive(`https://wallet.dev.evzone.app`) ? "active" : "nonactive" }>

                <img
                  src={getLinkIcon(`https://wallet.dev.evzone.app`)}
                  alt="transactions"
                  className="menu-icon "
                />
                <span>{t("Wallet")}</span>
              </Link>
            </li> 
            <li>
              <Link to={`/u/${login_user}/security`} className={isLinkActive(`/u/${login_user}/security`) ? "active" : "nonactive"}>

                <img
                  src={getLinkIcon(`/u/${login_user}/security`)}
                  alt="passwordsecurity"
                  className="menu-icon "
                />
                <span>{t("Password & Security")}</span>
              </Link>
            </li>
            <li>
            {isLinkActive(`/u/${login_user}/reviewratings`) ? (
              <Link to={`/u/${login_user}/reviewratings`} className="active">
                <img src={getLinkIcon(`/u/${login_user}/reviewratings`)} alt="Review Ratings" className="menu-icon " />
                <span>{t("Review Ratings")}</span>
              </Link>
            ) : (
              <Link to={`/u/${login_user}/reviews`} className={isLinkActive(`/u/${login_user}/reviews`) ? "active" : "nonactive"}>
                <img src={getLinkIcon(`/u/${login_user}/reviews`)} alt="Contact" className="menu-icon" />
                <span>{t("Reviews & Ratings")}</span>
              </Link>
            )}
          </li>
          <li>
              <Link to={`/u/${login_user}/parental`} className={isLinkActive(`/u/${login_user}/parental`) ? "active" : "nonactive"}>

                <img
                  src={getLinkIcon(`/u/${login_user}/parental`)}
                  alt="parental"
                  className="menu-icon "
                />
                <span>{t("Parental Control")}</span>
              </Link>
            </li>
           
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;

