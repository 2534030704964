import React, { useState } from 'react'
import "react-circular-progressbar"
import { Modal, Form } from 'react-bootstrap'
import './Actions.css'
import 'react-circular-progressbar/dist/styles.css'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import eye from '../images/eye.png'
import clock from '../images/clock.svg'
import plus from '../images/plus.png'

const Actions = () => {
  const [fullScreen, setFullScreen] = useState(true);
  const [show, setShow] = useState(false);

  const handlePopup = (breakpoint) => {
    setFullScreen(breakpoint);
    setShow(true);
  }

  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [showPassword, setShowPassword] = useState(false);
  
  const [timeLimit, setTimeLimit] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });
  
  const handleTimeChange = (e, field) => {
    setTimeLimit({
      ...timeLimit,
      [field]: parseInt(e.target.value),
    });
  };

  return (
    <div className='mainAction'>
      <div className='pt-2 pb-1 actionTitle'>
        <span className='pt-2 h6'>Actions</span><br />
      </div>

      <div className='mt-3 mb-4 justify-content-center align-items-center action_buttons'>
          <button className="button bg-primary alert">Alert</button>
          <br />
          <button className="button warning">Hide</button>
          <br />
          <button className="button lock" onClick={() => handlePopup()}>
            Lock
          </button>

        {/*LOCK OPTION POPUP*/}

        <Modal className='lockPopup'
          show={show}
          fullscreen={false}
          onHide={() => {
            setShow(false); // Close the modal
            setSelectedOption(null); // Reset the selected option
          }}>
          <Modal.Header closeButton className="pt-3 pb-2 px-4 ps-5 lockOptionsHeader" >
            <h5>Lock Options</h5>
          </Modal.Header>
          <Modal.Body className="px-0 ps-0 mx-2 ">
          <div className="row-cols-1 row-cols-lg-2 row popUpMain">
            <div className='col lockOptions'>
              <div className='optionButtons'>
                <div className="d-flex justify-content-between mb-3 py-2 px-2 align-items-center logOut">
                  <span>LogOut Instead</span>
                  <input
                    type="radio"
                    name="lockOption"
                    value="LogOut"
                    onChange={handleOptionChange}
                  />
                </div>

                <div className="d-flex justify-content-between mb-3 py-2 px-2 align-items-center nextDay">
                  <span>Until Next Day</span>
                  <input
                    type="radio"
                    name="lockOption"
                    value="NextDay"
                    onChange={handleOptionChange}
                  />
                </div>    

                <div className="d-flex justify-content-between mb-3 py-2 px-2 align-items-center repeat">
                  <span>Repeat</span>
                  <input
                    type="radio"
                    name="lockOption"
                    value="Repeat"
                    onChange={handleOptionChange}
                  />
                </div>

                <div className="d-flex justify-content-between mb-3 py-2 px-2 align-items-center onDemand">
                  <span>On Demand</span>
                  <input
                    type="radio"
                    name="lockOption"
                    value="OnDemand"
                    onChange={handleOptionChange}
                  />
                </div>
              </div>
            
              {/*LOGOUT DATA*/}
              {selectedOption === 'LogOut' &&(
                <div></div>    
              )}              
            </div>
              
              <div className="col">
                <div className='d-flex'>  
                <div className='d-none d-lg-block ms-0 me-0 pe-0 vr vertical-line' />

              {/*NEXTDAY DATA*/}

                {selectedOption === 'NextDay' && (
                  <div className="d-flex justify-content-center align-items-center ms-5 my-4 nextDayContent">
                    <CircularProgressbar
                      value={50}
                      text={'12:20:32'} 
                      styles={buildStyles({
                        textSize: '15px',
                        textColor: 'black',   
                        pathColor: 'orange',  
                        trailColor: 'lightgrey'
                      })}/>
                  </div>
                )}

              {/*REPEAT DATA*/}

                  {selectedOption === 'Repeat' && (
                    <div className='justify-content-left repeatColumn'>
                      <div className="mt-2 form-check form-switch custom-switch form-check-reverse sunday">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                          SUNDAY
                        </label>  
                      </div>

                        <div className="mt-2 form-check form-switch custom-switch form-check-reverse monday">
                          <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                            MONDAY
                          </label>  
                        </div>

                        <div className="mt-2 form-check form-switch custom-switch form-check-reverse tuesday">
                          <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                            TUESDAY
                          </label>  
                        </div>

                        <div className="mt-2 form-check form-switch custom-switch form-check-reverse wednesday">
                          <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                            WEDNESDAY
                          </label>  
                        </div>

                        <div className="mt-2 form-check form-switch custom-switch form-check-reverse thursday">
                          <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                            THURDAY
                          </label>  
                        </div>

                        <div className="mt-2 form-check form-switch custom-switch form-check-reverse friday">
                          <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                            FRIDAY
                          </label>  
                        </div>

                        <div className="mt-2 form-check form-switch custom-switch form-check-reverse saturday">
                          <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                            SATURDAY
                          </label>  
                        </div>
                    </div>
                  )}

                  {selectedOption === 'OnDemand' &&(
                    <div className='onDemandContent'>
                      <Form className=''>
                        <Form.Group className='ms-2' style={{fontSize: '14px'}}>
                          <Form.Label>Passcode</Form.Label>
                          <div className='form-group'>
                            <Form.Control className='mt-1' type={showPassword ? 'text' : 'password'}  />
                            <img src={eye} className='end-0' onClick= {() => setShowPassword(!showPassword)} alt=''/>
                          </div>
                        </Form.Group>
                      </Form>

                      <Form className='my-3 mx-2'>
                        <div className='row timeLimit'>
                        <span className='my-1'>Time Limit</span><br />
                          <div className='col sm-4'>
                            <Form.Label>Days</Form.Label>
                              <Form.Select value={timeLimit.days} onChange={(e) => handleTimeChange(e, 'days')}>
                                <option value={0}>0</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                                <option value={8}>8</option>
                                <option value={9}>9</option>
                                <option value={10}>10</option>
                                <option value={11}>11</option>
                                <option value={12}>12</option>
                                <option value={13}>13</option>
                                <option value={14}>14</option>
                                <option value={15}>15</option>
                                <option value={16}>16</option>
                                <option value={17}>17</option>
                                <option value={18}>18</option>
                                <option value={19}>19</option>
                                <option value={20}>20</option>
                                <option value={21}>21</option>
                                <option value={22}>22</option>
                                <option value={23}>23</option>
                                <option value={24}>24</option>
                                <option value={25}>25</option>
                                <option value={26}>26</option>
                                <option value={27}>27</option>
                                <option value={28}>28</option>
                                <option value={29}>29</option>
                                <option value={30}>30</option>
                                <option value={31}>31</option>
                            </Form.Select>
                          </div>

                          <div className='col-sm-4'>
                          <Form.Label>Hours</Form.Label>
                              <Form.Select value={timeLimit.hours} onChange={(e) => handleTimeChange(e, 'hours')}>
                                <option value={0}>0</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                                <option value={8}>8</option>
                                <option value={9}>9</option>
                                <option value={10}>10</option>
                                <option value={11}>11</option>
                                <option value={12}>12</option>
                                <option value={13}>13</option>
                                <option value={14}>14</option>
                                <option value={15}>15</option>
                                <option value={16}>16</option>
                                <option value={17}>17</option>
                                <option value={18}>18</option>
                                <option value={19}>19</option>
                                <option value={20}>20</option>
                                <option value={21}>21</option>
                                <option value={22}>22</option>
                                <option value={23}>23</option>
                              </Form.Select>
                          </div>

                          <div className='col-sm-4'> 
                          <Form.Label>Minutes</Form.Label>
                              <Form.Select value={timeLimit.minutes} onChange={(e) => handleTimeChange(e, 'minutes')}>
                                <option value={0}>0</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                                <option value={8}>8</option>
                                <option value={9}>9</option>
                                <option value={10}>10</option>
                                <option value={11}>11</option>
                                <option value={12}>12</option>
                                <option value={13}>13</option>
                                <option value={14}>14</option>
                                <option value={15}>15</option>
                                <option value={16}>16</option>
                                <option value={17}>17</option>
                                <option value={18}>18</option>
                                <option value={19}>19</option>
                                <option value={20}>20</option>
                                <option value={21}>21</option>
                                <option value={22}>22</option>
                                <option value={23}>23</option>
                                <option value={24}>24</option>
                                <option value={25}>25</option>
                                <option value={26}>26</option>
                                <option value={27}>27</option>
                                <option value={28}>28</option>
                                <option value={29}>29</option>
                                <option value={30}>30</option>
                                <option value={31}>31</option>
                                <option value={32}>32</option>
                                <option value={33}>33</option>
                                <option value={34}>34</option>
                                <option value={35}>35</option>
                                <option value={36}>36</option>
                                <option value={37}>37</option>
                                <option value={38}>38</option>
                                <option value={39}>39</option>
                                <option value={40}>40</option>
                                <option value={41}>41</option>
                                <option value={42}>42</option>
                                <option value={43}>43</option>
                                <option value={44}>44</option>
                                <option value={45}>45</option>
                                <option value={46}>46</option>
                                <option value={47}>47</option>
                                <option value={48}>48</option>
                                <option value={49}>49</option>
                                <option value={50}>50</option>
                                <option value={51}>51</option>
                                <option value={52}>52</option>
                                <option value={53}>53</option>
                                <option value={54}>54</option>
                                <option value={55}>55</option>
                                <option value={56}>56</option>
                                <option value={57}>57</option>
                                <option value={58}>58</option>
                                <option value={59}>59</option>
                              </Form.Select>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}

                </div>
              </div>
          </div>

          <div className='row popUpSecond'>
                
                {selectedOption === 'Repeat'  &&(
                  <div className='repeatRow'>
                    <div>
                      <hr />
                      <div>
                        <h6>Total Daily Screen Time</h6>
                        <div className= 'd-flex my-2 align-items-center justify-content-between totalTime'>
                          <label className='ms-2'>7h 15m</label>
                          <img src={clock} className='me-2' alt='' />
                        </div>
                      </div>
                    </div>
                  
                    <div className='d-flex timeStat'>
                      <div>
                        <h6>Start Time</h6>
                        <div className= 'd-flex my-2 align-items-center justify-content-between startTime'>
                          <label className='ms-2'>09:00 AM </label>
                          <img src={clock} className='me-1' alt='' />
                        </div>
                      </div>

                      <div>
                        <h6 className='mt-4'>to</h6>
                      </div>

                      <div>
                        <h6>End Time</h6>
                        <div className= 'd-flex my-2 align-items-center justify-content-between endTime'>
                          <label className='ms-2'>12:00 PM </label>
                          <img src={clock} className='me-1' alt='' />
                        </div>
                      </div>

                      <div>
                        <img src={plus} className='mt-3' alt='' />
                      </div>
                    </div>
                    <hr />
                    <button id='submit' className='px-4 py-1'>Submit</button>
                  </div>
                )}

                {selectedOption === 'NextDay'  &&(
                  <div className=''>
                    <hr />
                    <button id='submit' className='px-4 py-1'>Submit</button>
                  </div>
                )}

                {selectedOption === 'OnDemand'  &&(
                  <div className=''>
                    <hr />
                    <button id='submit' className='px-4 py-1'>Submit</button>
                  </div>
                )}

                
          </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}

export default Actions