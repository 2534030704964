import React, { useState, useEffect, useRef,useCallback } from "react";
import { APIProvider, Map, Marker, useMap, } from '@vis.gl/react-google-maps';
import axios from 'axios';
import back from '../../../images/arrow-left-01.png';
import locationcurrent from '../../../images/address-icons/Group 1488.png';
import { GoogleMapurl} from "../../../api/helper/urlhelper";
import Navbars from "../../../navbar";
import { useIpContext } from "../../../api/hooks/iphook";
import PlaceAutocomplete from "../../addresssection/autocomplete";
import { useNavigate } from "react-router-dom";
import SearchedLocation from "./seachedlocation";
import SearchedLocationconfirm from "./searchedlocationconfirm";
import Searchedlocationstore from "./searchedlocationstore";
import { extractLoginUser } from "../../../api/utils/utils";
import { useCountryData } from "../../../api/hooks/mobilehook";
const googlemapkey = GoogleMapurl();
const INITIAL_CAMERA = {
  center: { lat: 0, lng: 0 },
  zoom: 10,
};
const MapHandler = ({ place, marker }) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !place || !marker) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry.viewport);
    }

    marker.setPosition(place.geometry?.location);
  }, [map, place, marker]);

  return null;
};
function AddAddress({login_user}) {
  const { getUserIpCountryCode,latitude,longitude} = useIpContext();
  const {
    mccId, dialCode, countryCode, countryOptions,mobile_length,mobile_starting_digits,isLoading,setMccId,setDialCode,setCountryCode,setMobile_length,setMobile_starting_digits,
    loadCountryByIp,
  } = useCountryData();
  const [cameraProps, setCameraProps] = useState(INITIAL_CAMERA);
  const [loading, setLoading] = useState(false);
  const fetchLocation = useCallback(async () => {
    setLoading(true);
    try {
      await getUserIpCountryCode();
    } catch (error) {
      console.error("Error fetching user IP location:", error);
    } finally {
      setLoading(false);
    }
  }, [getUserIpCountryCode]);

    useEffect(() => {
      if (latitude && longitude) {
        setCameraProps({
          center: { lat: latitude, lng: longitude },
          zoom: 10
        });
      } else {
        fetchLocation(); // Call this to ensure the values are fetched
      }
    }, [latitude, longitude, fetchLocation]); // Include fetchLocation in the dependency array
    
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [mobileChange, setMobileChange] = useState('');
  const [markerPosition, setMarkerPosition] = useState(null);
  const [locationState, setLocationState] = useState({ searchLocation: true, searchedLocation: false,searchedLocationconfirm :false,searchedLocationstore:false });
  const [addressComponents, setAddressComponents] = useState({
    street: "",
    country: "",
    city: "",
    zipcode: "",
    state: "",
    latitude: "",
    longitude: ""
  });
  const [formValues, setFormValues] = useState({
    Name:'',
    mcc_id:'',
    houseNumber: '',
    apartment: '',
    addressLine2: '',
    addressType: '',
    otherType: '',
    default:false,
    city:'',
    postcode:'',
    fetchcondition:false,
    moduleoption:[],
    module:''
  });
  const emptynull = () => {
    setMarkerPosition(null);
    setAddressComponents((prevstate)=>({
      ...prevstate,street:'', country: "",city: "",zipcode: "",state: "",latitude: "",longitude: "",code:''
    }));

  }
  const countryData = {
    mccId,
    dialCode,
    countryCode,
    countryOptions,
    mobile_length,
    mobile_starting_digits,
    isLoading,
    loadCountryByIp,
    setMccId,
    setDialCode,
    setCountryCode,
    setMobile_length,
    setMobile_starting_digits,
  };
  const [selectedPlace, setSelectedPlace] = useState(null);
  const markerRef = useRef(null);

  const handleMarkerClick = (event) => {
    if (event) {
      const clickedLatLng = {
        lat: event.detail.latLng.lat,
        lng: event.detail.latLng.lng
      };
      setCameraProps({ center: clickedLatLng, zoom: 15 });
      setMarkerPosition(clickedLatLng);
      fetchAddress(clickedLatLng);

    }
  };

  const fetchAddress = async (latLng) => {
    try {
      const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          latlng: `${latLng.lat},${latLng.lng}`,
          key: googlemapkey
        }
      });

      if (response.data.results.length > 0) {
        const addressData = response.data.results[0];
        const components = addressData.address_components;

        let street = "";
        let country = "";
        let city = "";
        let zipcode = "";
        let state = "";
        let code = "";
        components.forEach(component => {
          if (component.types.includes("route")) {
            street = component.long_name;
          }
          if (component.types.includes("country")) {
            country = component.long_name;
            code = component.short_name;
          }
          if (component.types.includes("locality")) {
            city = component.long_name;
          }
          if (component.types.includes("postal_code")) {
            zipcode = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
        });

        setAddressComponents({
          street,
          country,
          city,
          zipcode,
          state,
          latitude: latLng.lat,
          longitude: latLng.lng,
          code
        });
        setLocationState((prevstate) => ({
          ...prevstate,
          searchLocation: false,
          searchedLocation: true
        }));
      }
    } catch (error) {
      console.error("Error fetching geocode address:", error);
    }
  };

  const handleUseCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const currentLatLng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        setCameraProps({ center: currentLatLng, zoom: 15 });
        setLocationState((prevstate) => ({
          ...prevstate,
          searchLocation: false,
          searchedLocation: true
        }));
        setMarkerPosition(currentLatLng);
        fetchAddress(currentLatLng);
      }, (error) => {
        console.error("Error getting current location:", error);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const handleCameraChange = (ev) => {
    setCameraProps(ev.detail);
  };

  const handlePlaceSelect = (place) => {
    setSelectedPlace(place);
    setCameraProps({ center: place.geometry.location, zoom: 15 });
    setMarkerPosition(place.geometry.location);
    fetchAddress({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    });
  };
  const confirmAndProceed = () => {
    setLocationState((prevstate) => ({
      ...prevstate,
      searchedLocation: false,
      searchLocation:false,
      searchedLocationconfirm: true,
      searchedLocationstore:false,
    }));
    setFormValues((prevstate)=>({
      ...prevstate,fetchcondition:false
    }));
  };
  const confirmAndProceed1 = () => {
    setLocationState((prevstate) => ({
      ...prevstate,
      searchedLocation: false,
      searchLocation:false,
      searchedLocationconfirm: false,
      searchedLocationstore:true,
    }));
    setFormValues((prevstate)=>({
      ...prevstate,fetchcondition:true
    }));
    setError('');
  };
  const back1 = async() => {
    emptynull();
    if (latitude && longitude) {
      setCameraProps({
        center: { lat: latitude, lng: longitude },
        zoom: 10
      });
    } else {
      fetchLocation(); // Call this to ensure the values are fetched
    }
    setLocationState((prevstate) => ({
      ...prevstate,
      searchedLocation: false,
      searchLocation:true,
      searchedLocationconfirm: false,
      searchedLocationstore:false,
    }));
  }
  const back2 = () => {
    setLocationState((prevstate) => ({
      ...prevstate,
      searchedLocation: true,
      searchLocation:false,
      searchedLocationconfirm: false,
      searchedLocationstore:false,
    }));
    setMobileChange('');
    setMccId('');
    setCountryCode('');
    setFormValues((prevstate)=>({
      ...prevstate,
      Name:'',
      houseNumber: '',
      apartment: '',
      addressLine2: '',
      addressType: '',
      otherType: '',
      default:false,
      city:'',
      postcode:'',
      fetchcondition:false,
         moduleoption:[],
    module:'',
    modulecheckbox:false
    }))
  }
  const back3 = () => {
    setLocationState((prevstate) => ({
      ...prevstate,
      searchedLocation: false,
      searchLocation:false,
      searchedLocationconfirm: true,
      searchedLocationstore:false,
    }));
    setFormValues((prevstate)=>({
      ...prevstate,default:false
    })); 
  }
  const Change = () =>{
    setLocationState((prevstate) => ({
      ...prevstate,
      searchedLocation: false,
      searchLocation:true,
      searchedLocationconfirm: false,
      searchedLocationstore:false,
    }));
    if (latitude && longitude) {
      setCameraProps({
        center: { lat: latitude, lng: longitude },
        zoom: 10
      });
    } else {
      fetchLocation(); // Call this to ensure the values are fetched
    }
    setMobileChange('');
    setMccId('');
    setCountryCode('');
    setFormValues((prevstate)=>({
      ...prevstate,
      Name:'',
      houseNumber: '',
      apartment: '',
      addressLine2: '',
      addressType: '',
      otherType: '',
      default:false,
      city:'',
      postcode:'',
      fetchcondition:false,
         moduleoption:[],
    module:''
    }))
    setAddressComponents((prevstate)=>({
      ...prevstate,
      street: "",
      country: "",
      city: "",
      zipcode: "",
      state: "",
      latitude: "",
      longitude: ""
    }));
    setMarkerPosition(null);
    fetchLocation();
  }
  const backprofile = () => {
    navigate(`/u/${login_user}/profile`);
  }
  return (
    <>
      <div>
        <div className="container-fluid" style={{ height: '100px' }}>
          <Navbars/>
        </div>
        <div className="address_div">
          <div className="address_div1">
            {locationState.searchLocation && (
              <>
              <div className="address_divhalf1">
                <div className="d-flex align-items-center">
                  <img src={back} onClick={backprofile} className="me-2" style={{cursor:'pointer'}} alt="back" width="17px" height="14px" />
                  <h6 className="addaddressh6">Add Address</h6>
                </div>
                <div>
                  <p className="addaddressSearchp">Search Location</p>
                  <button onClick={handleUseCurrentLocation} className="addaddresscurrentbutton">
                    <img src={locationcurrent} className="locationcurrent" alt="upload" />
                    Use my current location
                  </button>
                  <PlaceAutocomplete onPlaceSelect={handlePlaceSelect} />
                </div>
              </div>
              <div className="address_divhalf2">
              {loading ? (
                    <div className="skeleton-loader1"></div>
                  ) : (
                    <APIProvider apiKey={googlemapkey} solutionChannel="GMP_devsite_samples_v3_rgmbasicmap">
                      <Map {...cameraProps} onCameraChanged={handleCameraChange} gestureHandling="greedy" onClick={handleMarkerClick}>
                        {markerPosition && <Marker position={markerPosition} onClick={handleMarkerClick} ref={markerRef} />}
                      </Map>
                      <MapHandler place={selectedPlace} marker={markerRef.current} />
                    </APIProvider>
                  )}
            </div>
            </>
            )}
            {locationState.searchedLocation && (
              <SearchedLocation addressComponents={addressComponents}
              cameraProps={cameraProps}
              markerPosition={markerPosition}
              selectedPlace={selectedPlace}
              googlemapkey={googlemapkey}
              onCameraChanged={handleCameraChange}
              confirmAndProceed={confirmAndProceed}
              back1={back1}
              Change={Change}
              // handleMarkerClick={handleMarkerClick}
              handleCameraChange={handleCameraChange}/>
              // <div className="address_divhalf1">
              //   <div className="d-flex align-items-center">
              //     <img src={back} className="me-2" alt="back" width="17px" height="14px" />
              //     <h6 className="addaddressh6">Add Address</h6>
              //   </div>
              //   <div>
              //     <p className="addaddressSearchp">Confirm your address</p>
              //     <div className='combinedaddressdiv'>
              //       <div>
              //         <img src={searchedlocationicon} className='searchedlocationimage' alt='searchlocate' ></img>
              //         <div>
              //           <p>{storeplaceaddress.street}</p>
              //           <p>{storeplaceaddress.city}</p>
              //           <p>{`${storeplaceaddress.country}-${storeplaceaddress.zipcode}`}</p>
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              // </div>
            )}
              {locationState.searchedLocationconfirm && (
              <SearchedLocationconfirm
                addressComponents={addressComponents}
                cameraProps={cameraProps}
                back2={back2}
                markerPosition={markerPosition}
                selectedPlace={selectedPlace}
                googlemapkey={googlemapkey}
                onCameraChanged={handleCameraChange}
                confirmAndProceed1={confirmAndProceed1}
                handleCameraChange={handleCameraChange}
                formValues={formValues}
                setFormValues={setFormValues}
                error={error}
                setError={setError}
                countryData={countryData}
                setMobileChange={setMobileChange}
                mobileChange={mobileChange}
              />
            )}
            {locationState.searchedLocationstore && (
              <Searchedlocationstore
              addressComponents={addressComponents}
                cameraProps={cameraProps}
                back3={back3}
                markerPosition={markerPosition}
                Change={Change}
                selectedPlace={selectedPlace}
                googlemapkey={googlemapkey}
                onCameraChanged={handleCameraChange}
                handleCameraChange={handleCameraChange}
                formValues={formValues}
                setFormValues={setFormValues}
                countryData={countryData}
                mobileChange={mobileChange}
                login_user={login_user}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddAddress;
