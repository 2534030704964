import {React,useState,useEffect} from "react";
import { useNavigate , useLocation} from "react-router-dom";
// import { UserProvider,useUserContext } from "./api/usercontext";
import pencil from "../images/edit-01.png";
import Navbars from "../navbar";
import leftarrow from '../images/arrow-left-01.png';
import eye from '../images/eye-01.png';
import eyeslash from '../images/eye_slash-01.png';
import eyegrey from '../images/eye-01pass.png';
import eyeslashgrey from '../images/eye-slash-01passw.png';
import '../home.css';
import { profileupdateotpapi , profileupdateauthapi , resendotpapi , passwordverifyapi} from "../api/helper/urlhelper";
// import axios from "axios";
import axiosInstance from "../api/utils/axiosutils";
import { extractLoginUser , handleApiError, handleInputChangeotp, handlePaste} from "../api/utils/utils";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserProfile } from "../api/redux/userProfiles/actions";
import WithToast from "../api/hooks/withtoasthook";
const proflleupdateotpurl = profileupdateotpapi();
const profileupdateauthurl = profileupdateauthapi();
const passwordverifyapiurl = passwordverifyapi();
 // const resendotpurl = resendotpapi(); 
function Emailchange({showErrorToast}) {
  const login_user = extractLoginUser();
  const location = useLocation();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userProfile.userProfile); 
  useEffect(() => {
      dispatch(fetchUserProfile());
  },[dispatch]);
  const { state } = location;
  const email = state && state.userEmail ? state.userEmail : '';
  const [emailchange , setEmailchange] = useState('');
  const [showemailedit ,setShowemailedit] = useState(false);
  const [showemailedits , setShowemailedits] = useState(false);
  const [showemailotppage , setShowemailotppage ] = useState(false);
  const [otpchange , setOtpchange] = useState('');
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [isSaveButtonEnablednext, setIsSaveButtonEnablednext] = useState(false);
  const [isSaveButtonEnablednextotp, setIsSaveButtonEnablednextotp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error422, setError422] = useState('');
  const [isLoading4, setIsLoading4] = useState(false);
  const navigate = useNavigate();
  const [showemailpasswordedit , setShowemailpasswordedit] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [password , setPassword] = useState('');
  const toggleVisibility = () => {
    setShowPassword(!showPassword);
  }
  const goBack = () => {
    navigate(`/u/${login_user}/profile`);
  }
  const cancelPasswordChange = () => {
    navigate(`/u/${login_user}/profile`);
  }
  const check = () => {
    const valid = (
      password !== ''
    )
    setIsSaveButtonEnabled(valid);
  }
  useEffect(()=>{
    check();
  },[password])
  const goToNextStep = async() => {
  //   const token = localStorage.getItem('access_token');
  //   const headers = {
  //     'Authorization': `Bearer ${token}`,
  //     'Content-Type': 'application/json',
  // };
    const data = {
      password : password,
    }
    try{
      setIsLoading4(true);
    const response = await axiosInstance.post(passwordverifyapiurl,data);
    if (response.status === 200) {
      const password_verify = response.data.data.password_verify;
      localStorage.setItem('password_verify', password_verify);
     setIsLoading4(false);
     setShowemailedit(false);
     setShowemailpasswordedit(false);
    setShowemailedits(true);
    setError422(false);
    setShowemailotppage(false);
    // startCountdown1();
  }
    }
    catch(error){
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }
  }
  const back = () => {
    navigate(`/u/${login_user}/profile`);
  }
  const cancel = () => {
    navigate(`/u/${login_user}/profile`);
  }
  const backs = () => {
    setShowemailedits(false);
    setShowemailpasswordedit(true);
    setPassword('');
  }
  const emailedit = () => {
    setShowemailedits(true);
    setShowemailedit(false);
    setShowemailotppage(false);
  }
//   const startCountdown1 = () => {
//     let seconds = 10;
//     const intervalId = setInterval(() => {
//         if (seconds > 0) {
//             seconds--;
//             setCountdown1(seconds);
//         } else {
//             clearInterval(intervalId);
//         }
//     }, 1000);
// };
// const resendotp = async() => {
//    const token = localStorage.getItem('access_token');
//    const headers = {
//        'Authorization': `Bearer ${token}`,
//        'Content-Type': 'application/json',
//    };
//    const data = JSON.stringify({
//      email : emailchange,
//      verify : "email"
//    })
//    try {
//        const response = await axios.post(resendotpurl,data,{ headers });
//        if (response.status === 200) {
//            const token = response.data.data.access_token;
//            localStorage.setItem('access_token', token);
//            const otptoken = response.data.data.token;
//            localStorage.setItem('otp_token',otptoken)
//            toast.success("Resend OTP sent successfully...", {
//             autoClose: 2000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//                 progress: undefined,
//                 theme: "colored",
//                 backgroundColor :'#02CD8D'
//           });
//           startCountdown1();
//        }
//    }
//    catch (error) {
//        if (error.response) {
//            if (error.response.status === 401) {
//                toast.error(error.response.data.mobile_error, {
//                 position: "top-right",
//                 autoClose: 2000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//                 progress: undefined,
//                 theme: "colored",
//                 });
//            } else if (error.response.status === 422) {
//             toast.error(error.response.data.mobile_error, {
//                 position: "top-right",
//                 autoClose: 2000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//                 progress: undefined,
//                 theme: "colored",
//                 });
//            }
//        }
//    }
// }
   const checks = () => {
    const valid = (
      emailchange !== ''
    );
    setIsSaveButtonEnablednext(valid);
   }
   useEffect(()=>{
    checks();
   },[emailchange]);
  const next = async() => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(emailchange)) {
      setError422('Please enter a valid email address.');
      return;
    }
  //   const token = localStorage.getItem('access_token');
    const password_verify = localStorage.getItem('password_verify');
  //   const headers = {
  //     'Authorization': `Bearer ${token}`,
  //     'Content-Type': 'application/json',
      
  // };
    const data = {
      email : emailchange,
       password_verify_token : password_verify,
      verify : 'email'
    }
    try{
      setIsLoading4(true);
    const response = await axiosInstance.post(proflleupdateotpurl,data);
    if (response.status === 200) {
      const otptoken = response.data.data.token;
      localStorage.setItem('otp_token', otptoken);
      // showSuccessToast(response.data.message);
     setShowemailedit(false);
     setIsLoading4(false);
    setShowemailedits(false);
    setShowemailotppage(true);
    // startCountdown1();
  }
    }
    catch(error){
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }
  }
  const checkotp = () => {
    const valid = (
      otpchange !== ''
    );
    setIsSaveButtonEnablednextotp(valid);
  }
  useEffect(()=>{
    checkotp();
  },[otpchange]);
  const onPaste1 = (e) => {
    handlePaste(e, setOtpchange); 
    setError422('');
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setOtpchange(value);
    setError422('');
  };
  const save = async() => {
  //   const token = localStorage.getItem('access_token');
    const password_verify = localStorage.getItem('password_verify');
  //   const headers = {
  //     'Authorization': `Bearer ${token}`,
  //     'Content-Type': 'application/json',
  // };
  const otptoken = localStorage.getItem('otp_token');
  const data = {
    otp : otpchange,
    password_verify_token : password_verify,
    token : otptoken
  }
  try{
    setIsLoading4(true);
  const response = await axiosInstance.post(profileupdateauthurl,data,);
  if (response.status === 200) {
    setIsLoading4(false);
    setError422('');
    // const token = response.data.data.access_token;
    // localStorage.setItem('access_token', token);
    // showSuccessToast(response.data.message);
    navigate(`/u/${login_user}/profile`);
}    
  }
  catch(error){
    if(error.response){
      if(error.response.status === 422){
       const error_code = error.response.data.code;
       if (error_code === 'otp_retry') {
         navigate(`/u/${login_user}/profile`);
         setIsLoading4(false);
       }else{
         handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
       }
      } else{
       handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
      }
   }else{
   handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
   }
  }   
  }
  return (
    <>
      {isLoading4 && (
        <>
          <div className="loaderover">
            <div className="loader "></div>
          </div>
        </>
      )}
      <div>
        <div className="container-fluid" style={{ height: '100px' }}>
          <Navbars />
        </div>
        <div className="name_div">
        {showemailpasswordedit && (
         <>
          <div className="name_div1">
            <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={goBack} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">Edit Email</h5>
            </div>
            <div className="name-input name-inputimage">
            <p style={{ fontWeight: '500', fontSize: '16px', lineHeight: '30px', color: '#292929' }}>To modify Email, Kindly enter your password</p>
            <div className="header-profile-user border-dark user mx-auto " style={{ width: '80px', height: '80px', borderRadius: '50%', overflow: 'hidden' }}>
            <img id="login_img_for_profile" src={userProfile?.image} alt="Profile" loading="lazy"className=" homeprofile mb-3" style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}/></div>
            <p style={{ fontWeight: '500', fontSize: '20px', lineHeight: '30px', color: '#292929' }}>{userProfile?.name}</p>
            <div className="text-start" style={{width:'100%'}}>
                <label htmlFor="password" className="mb-2" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Current Password</label>
              <div className="password " style={{ position: 'relative' }}>
                <input type={showPassword ? 'text' : 'password'} value={password} onChange={(e)=>{setPassword(e.target.value);setError422('')}} className={`form-control docform ${password ? 'docform-active' : ''}`}  id="userpassword" placeholder="Enter Your Current Password" name="password"/>
                <p className="toggle-password" onClick={toggleVisibility} style={{ position: 'absolute', marginRight: '15px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                  {showPassword ? (
                    password ? (
                    <img src={eye} className='eye' width='19.5px' height='19.5px' alt="Show Password"/>
                    ) : (
                      <img src={eyegrey} className='eye' width='19.5px' height='19.5px' alt="Show Password"/>
                    )
                  ) : (
                    password ? (
                    <img src={eyeslash} className='eye' width='19.5px' height='19.5px' alt="Hide Password" />
                    ) : (
                      <img src={eyeslashgrey} className='eye' width='19.5px' height='19.5px' alt="Hide Password" />
                    )
                  )}
                </p>
              </div>
              </div>
              <div className="error-message error-messageprofile">{error422}</div>
              <div className="name_button_div" style={{width:'100%'}}>
                <button type="button" onClick={cancelPasswordChange} className="profilecancel me-4">
                  Cancel
                </button>
                <button type="button" onClick={goToNextStep} style={{ backgroundColor: isSaveButtonEnabled ? '#02CD8D' : '#EAEAEA', color: isSaveButtonEnabled ? '#FFF' : '#333' , cursor: isSaveButtonEnabled ? 'pointer' : 'not-allowed' }} disabled={!isSaveButtonEnabled} className={`profilesave`}>
                  Next
                </button>
              </div>
            </div>
            </div>
            </>
            )}
            
             {showemailedits &&(
              <>
              <div className="name_div1">
            <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={backs} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">Change Email</h5>
            </div>
            <div className="name-input name-inputimage name-inputimageemail ">
            <div>
                  <label htmlFor="inputField"  className="form-label" style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#000000'}}>Email Address</label>
                  <input type="email" value={emailchange} onChange={(e)=> {setEmailchange(e.target.value);setError422('')}} className={`form-control docform ${emailchange ? 'docform-active' : ''}`}  id="inputField" placeholder="Enter Email" />
                </div>
                <div className="error-message error-messageprofile">{error422}</div>
                <div className="name_button_div">
                <button type="button" onClick={cancel} className="me-3  profilecancel">
                  Cancel
                </button>
                <button type="button" onClick={next} style={{ backgroundColor: isSaveButtonEnablednext ? '#02CD8D' : '#EAEAEA', color: isSaveButtonEnablednext ? '#FFF' : '#333' , cursor: isSaveButtonEnablednext ? 'pointer' : 'not-allowed' }} disabled={!isSaveButtonEnablednext} className={`profilesave`}>
                  Next
                </button>
              </div>
              </div>
            </div>
              </>
             )}
             {showemailotppage && (
              <>
                <div className="name_div1">
            <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={back} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">Verify Email</h5>
            </div>
            <div className="name-input name-inputimage name-inputimageemail ">
            <div>
                  <label htmlFor="inputField"  className="form-label" style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#000000'}}>Verification Code</label>
                  <input type="text" value={otpchange}  onChange={handleChange}  maxLength={6} onPaste={onPaste1} onKeyPress={handleInputChangeotp} className={`form-control docform ${otpchange ? 'docform-active' : ''}`}  id="inputField" placeholder="Enter Verification Code" />
                </div>
                <div className="error-message error-messageprofile">{error422}</div>
                <div className="name_button_div">
                <button type="button" onClick={cancel} className="me-3  profilecancel">
                  Cancel
                </button>
                <button type="button" onClick={save} style={{ backgroundColor: isSaveButtonEnablednextotp ? '#02CD8D' : '#EAEAEA', color: isSaveButtonEnablednextotp ? '#FFF' : '#333' , cursor: isSaveButtonEnablednextotp ? 'pointer' : 'not-allowed' }} disabled={!isSaveButtonEnablednextotp} className={`profilesave`}>
                  Save
                </button>
              </div>
              </div>
            </div>
              </>
             )}
             </div>
            </div>
    </>
  );
}
export default WithToast(Emailchange);