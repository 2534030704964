import React, { useState } from "react";
import Sidebar from "./sidebar";
import Navbars from "./navbar";
import './parental.css';
import './childdetails.css';
import parentalimg from './images/parental_control.png';
import cancel from './images/cancel-01.png';
// import { useNavigate } from "react-router-dom";
// import { extractLoginUser } from "./api/utils/utils";
// import Modal from './Modal';

function Detailsconfigure() {


    return (
        <>
            <Navbars />
            <div className="container-fluid bg-light" style={{ padding: '0px' }}>
                <div className="documentrow">
                    <div className="col-12 col-md-3 col-lg-3  documentside">
                        <Sidebar />
                    </div>

                    <div className="col-12 col-md-9 col-lg-9 documentmain">
                        <div className="parentalmain bg-light">
                            <div className="parentalmainfirst d-flex justify-content-between">
                                <h5 className="heading">EVzone Marketplace</h5>
                                <button className="addminor-btn me-4">Configure</button>
                            </div>
                            <div className="detailsconfigurebox">
                                <div className="row mt-4 ">
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-sm-3">
                                        <div className="detailsconfigure">
                                            <p>Configure Plan</p>
                                            <p><strong>Configure Plan</strong></p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-sm-3">
                                        <div className="detailsconfigure">
                                            <p>Lock Plan</p>
                                            <p><strong>Strict</strong></p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-sm-3">
                                        <div className="detailsconfigure">
                                            <p>Last Seen</p>
                                            <p><strong>Yesterday</strong></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-sm-3">
                                        <div className="detailsconfigure">
                                            <p>Last Duration</p>
                                            <p><strong>3 hr 14 mins</strong></p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-sm-3">
                                        <div className="detailsconfigure">
                                            <p>Unsuccessful attempts</p>
                                            <p><strong>10</strong></p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-sm-3">
                                        <div className="detailsconfigure">
                                            <p>Last Successful Attempts</p>
                                            <p><strong>Last Saturday</strong></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-sm-3">
                                        <div className="detailsconfigure">
                                            <p>Successful Attempts</p>
                                            <p><strong>24</strong></p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-sm-3">
                                        <div className="detailsconfigure">
                                            <p>Last Successful Attempts</p>
                                            <p><strong>Yesterday</strong></p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-sm-3">
                                        <div className="detailsconfigure">
                                            <p>Status</p>
                                            <p><strong>Active</strong></p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Detailsconfigure;