import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbars from "./navbar";
import leftarrow from './images/arrow-left-01.png';
import { extractLoginUser } from "./api/utils/utils";
function Twofactoremailauth() {
  const login_user = extractLoginUser();
  const [selectedOption, setSelectedOption] = useState("");
  const [isNextButton, setIsNextButton] = useState(false);
  const navigate = useNavigate();
  const back = () => {
    navigate(`/u/${login_user}/twofactorauth`);
  }
  const twofactortextcancel = () => {
    navigate(`/u/${login_user}/twofactorauth`)
  }
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
    const selectedradio = (event.target.value);
    if(selectedradio === 'anotheremail'){
      setIsNextButton(true);
    }
    else{
      setIsNextButton(false);
    }
  };
  const twofactortextnext = () => {
    if (selectedOption === "email") {
      setSelectedOption("anotheremail");
      setIsNextButton(true);
    }
    else if(selectedOption === "anotheremail"){
      setIsNextButton(true);
      navigate(`/u/${login_user}/twofactoremail`);
    }
  };
  return (
    <>
      <Navbars />
      <div className="container-fluid textmessageauthendiacation">
        <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="col-lg-7 col-md-8 col-sm-10">
            <div className="box" style={{ backgroundColor: 'white',width:'100%',maxWidth:'620px',minHeight:'385px',borderRadius:'18px',border:'1px solid #ABA7A7',padding:'60px 50px' }}>
              <div className="d-flex  align-items-center">
              <img onClick={back} src={leftarrow} alt="leftarrow" width='19.5px' height='15.5px' className="mb-3 me-4"style={{cursor:'pointer'}}></img>
                <h5 className='mb-3'style={{fontWeight:'500',fontSize:'24px',lineHeight:'36px',color:'#232323'}}>Email Authentication</h5>
              </div>
              <div className="form-group ps-5">
                <label className="mb-3" style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#000000'}} htmlFor="mobileNumber">Email address</label>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="form-control-static" style={{fontWeight:'400',fontSize:'16px',lineHeight:'24px',color:'#323232'}}>evzone@gmail.com</p>
                  <div className="form-check">
                    <input type="radio" id="mobilenumber" className="form-checks" value='email' style={{width:'16px',height:'16px',marginTop:'-13px'}} checked={selectedOption === "email"} onChange={handleRadioChange} />
                    <label className="form-check-label" htmlFor="radioButton"></label>
                  </div>
                </div>
                <hr className="mt-0"style={{color:'#EAE9E9',height:'2px'}}></hr>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="form-control-static" style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#323232'}}>Use another email address</p>
                  <div className="form-check">
                    <input type="radio" id="anothernumber" className="form-checks" value='anotheremail' style={{width:'16px',height:'16px',marginTop:'-18px'}} checked={selectedOption === "anotheremail"} onChange={handleRadioChange} />
                    <label className="form-check-label" htmlFor="anothernumber"></label>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-4">
                <button onClick={twofactortextcancel} type="button" className=" profilecancel  me-2">Cancel</button>
                <button onClick={twofactortextnext} type="button" className={` ${isNextButton ? 'profilesave' : 'profilesave'}`}>{isNextButton ? 'Next' : 'Save'}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
}
export default Twofactoremailauth;