import {React,useEffect,useState,useCallback} from "react";
import { useNavigate } from "react-router-dom";
import Navbars from "../navbar";
import leftarrow from '../images/arrow-left-01.png'
import Select, { components } from 'react-select';
import axiosInstance from "../api/utils/axiosutils";
import { countrystyle, extractLoginUser,handleApiError, handleInputChangeotp, handlePaste, selectWidth, validateAndSetMobileChange } from "../api/utils/utils";
import {otpupdatesendapi , recoveryemailapi} from "../api/helper/urlhelper";
import WithToast from "../api/hooks/withtoasthook";
import { useCountryData } from "../api/hooks/mobilehook";
import useMenuHeight from "../api/hooks/useMenuHeight";
const otpupdatesendapiurl = otpupdatesendapi(); 
const recoveryemailapiurl = recoveryemailapi();
function Recoveryphone({showErrorToast}) {
  const login_user = extractLoginUser();
  const navigate = useNavigate();
  const {
    mccId, dialCode, countryCode, countryOptions,mobile_length,mobile_starting_digits,isLoading,setMccId,setDialCode,setCountryCode,setMobile_length,setMobile_starting_digits,
    loadCountryByIp,
  } = useCountryData();
  const [recoveryphone , setRecoveryphone] = useState(true);
  const [recoveryphonecode , setRecoveryphonecode] = useState(false);  
  const [mobileChange, setMobileChange] = useState('');
  const [error422, setError422] = useState('');
  const [isLoading4, setIsLoading4] = useState(false);
  const [otp, setOtp] = useState('');
  const [numberinvalid,setNumberinvalid] = useState(false);
  const [isSaveButtonEnablednextotp, setIsSaveButtonEnablednextotp] = useState(false);
  useEffect(()=>{
    loadCountryByIp(true);
  },[]);
  const [isSaveButtonEnablednext, setIsSaveButtonEnablednext] = useState(false);
  const handleKeyPress = (e) => {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];

    if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };
  const handlePhoneNumberChange = (value) => {
    validateAndSetMobileChange(value, {
      setMobileChange: setMobileChange,
      setError: setError422,
      setNumberInvalid: setNumberinvalid,
      mobile_length: mobile_length,             
      mobile_starting_digits: mobile_starting_digits 
    });
  };
  const handlePaste1 = (e) => {
    const pastedText = e.clipboardData.getData('text');
    let numericPastedText = pastedText.replace(/[^0-9]/g, '');
    const dialCodeWithoutPlus = dialCode.replace('+', '');
    if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
        numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
    }
    validateAndSetMobileChange(numericPastedText, {
      setMobileChange: setMobileChange,
      setError: setError422,
      setNumberInvalid: setNumberinvalid,
      mobile_length: mobile_length,             
      mobile_starting_digits: mobile_starting_digits 
    });
    e.preventDefault();
  };
  const handleCountryChange = (selectedOption) => {
    const selectedCountryData = countryOptions.find(
        (country) => country.value === selectedOption.value
    );
    if (selectedCountryData) {
      const { mcc_id, dial_code, country_code, mobile_length, mobile_starting_digits } = selectedCountryData;
  
      setMccId(mcc_id);
      setDialCode(`+${dial_code}`);
      setCountryCode(country_code);
      setMobile_length(mobile_length);
      setMobile_starting_digits(mobile_starting_digits);
  
      // Apply validation to existing mobile number
      validateAndSetMobileChange(mobileChange, {
        setMobileChange,
        setError: setError422,
        setNumberInvalid: setNumberinvalid,
        mobile_length,
        mobile_starting_digits,
      });
    }
};
  const customSingleValue = ({ data }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={data.flag}
        alt=""
        style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
      />
      {/* {data.label} */}
    </div>
  );

  const customOption = (props) => {
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={props.data.flag}
            alt=""
            style={{ width: '25px', height: 'auto', marginRight: '10px' }}
          />
          {props.data.label}
        </div>
      </components.Option>
    );
  };

  const back = () => {
    navigate(`/u/${login_user}/security`);
  }
  const cancel = () => {
    navigate(`/u/${login_user}/security`);
  }
  const checck = useCallback(() => {
    const valid = (
      mobileChange !== '' && numberinvalid === true
    )
    setIsSaveButtonEnablednextotp(valid);
  },[mobileChange,numberinvalid]);
  useEffect(()=>{
    checck();
  },[checck]);
  const next = async() => {
    // if(mobileChange.length < 9 || mobileChange.length > 15) {
    //   setError422('The mobile must be between 9 and 15 digits')
    //   return false;
    // }else{
    //   setError422('')
    // }
    const data ={
      mobile: mobileChange,
      mcc_id : mccId,
    }
    try {
      setIsLoading4(true);
      const response = await axiosInstance.post(otpupdatesendapiurl, data);
      if (response.status === 200) {
        setIsLoading4(false);
        const recovery_token = response.data.data.token;
        localStorage.setItem('recovery_token', recovery_token);
        setRecoveryphonecode(true);
        setRecoveryphone(false);
      }
    }
    catch (error) {
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }
  }
  const handleChange = (e) => {
    const value = e.target.value;
    setError422('');
    setOtp(value);
  };
  const onPaste1 = (e) => {
    handlePaste(e, setOtp);  // Reusing the common handlePaste function
    setError422('');
  };
  const backs = () => {
    setRecoveryphone(true);
    setRecoveryphonecode(false);
    setOtp('');
    setError422('');
    // navigate(`/u/${login_user}/security`);
  }
  const confirmcancel = () => {
    navigate(`/u/${login_user}/security`);
  }
  const checcks = () => {
    const valid = (
      otp !== ''
    )
    setIsSaveButtonEnablednext(valid);
  }
  useEffect(()=>{
    checcks();
  },[otp]);
  const confirmcode = async() => {
    const recovery_token = localStorage.getItem('recovery_token');
    const data ={
      mobile: mobileChange,
      mcc_id : mccId,
      otp : otp ,
      token : recovery_token
    }
    try {
      setIsLoading4(true);
      const response = await axiosInstance.post(recoveryemailapiurl, data,);
      if (response.status === 200) {
       setIsLoading4(false);
        navigate(`/u/${login_user}/security`);
        setRecoveryphone(false);
        setRecoveryphonecode(false);
      }
    }
    catch (error) {
      if(error.response){
        if(error.response.status === 422){
         const error_code = error.response.data.code;
         if (error_code === 'otp_retry') {
           navigate(`/u/${login_user}/security`);
           setIsLoading4(false);
         }else{
           handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
         }
        } else{
         handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
        }
     }else{
     handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
     }
    }
  }
  const [selwidth, setSelWidth] = useState(selectWidth(dialCode));
  const menuHeight = useMenuHeight(200, 400);
  useEffect(() => {
    setSelWidth(selectWidth(dialCode));
  }, [dialCode]);
  return (
    <>
     {isLoading4 && (
        <>
          <div className="loaderover">
            <div className="loader "></div>
          </div>
        </>
      )}
       <div>
        <div className="container-fluid" style={{ height: '100px' }}>
          <Navbars />
        </div>
        <div className="name_div">
        {recoveryphone && (
         <>
          <div className="name_div1">
            <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={back} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">Recovery Phone</h5>
            </div>
            <div className="name-input name-inputimage name-inputimageemail ">
            <div >
            <label
        htmlFor="inputField"
        className="form-label"
        style={{
          fontWeight: '500',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#000000',
        }}
      >
        Mobile Number
      </label>
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
        <div style={{ position: 'absolute', left: 0, zIndex: 1 }}>
          <Select
            options={countryOptions}
            onChange={handleCountryChange}
            value={countryOptions.find(option => option.value === countryCode)}
            components={{ SingleValue: customSingleValue, Option: customOption }}
            placeholder=""
            isLoading={isLoading} // Pass the loading state to Select
            styles={countrystyle('82px',menuHeight)}
          />
        </div>
        <div style={{ height: '30px', width: '2px', backgroundColor: '#ccc', margin: '0 8px', position: 'absolute', left: `${selwidth}px`, top: '50%', transform: 'translateY(-50%)' }}></div>
        <p style={{ position: 'absolute', left:'78px', top: '51%', transform: 'translateY(-50%)', cursor: 'pointer',color:'hsl(0, 0%, 20%)',fontSize:'14px',lineHeight:'1.5',marginBottom:'0px',fontWeight:'400' }}>{dialCode}</p>
        <input
          type="text"
          className={`form-control docform ${mobileChange ? 'docform-active' : ''}`}
          id="mobile"
          style={{ paddingLeft: `${selwidth + 18}px`, width: '100%' }}
          placeholder="Mobile Number"
          value={mobileChange}
          name="mobile"
          onPaste={(e) => handlePaste1(e)}
          onKeyPress={handleKeyPress}
          onChange={(e) => handlePhoneNumberChange(e.target.value)}
        />
      </div>
      <input
        type="hidden"
        className="form-control signupemalichange"
        placeholder="Mobile Number"
        value={mccId}
        onChange={(e) => setMccId(e.target.value)}
      />
                </div>
                <div className="error-message error-messageprofile">{error422}</div>
                <div className="name_button_div">
                <button type="button" onClick={cancel} className="me-3  profilecancel">
                  Cancel
                </button>
                <button type="button" onClick={next} style={{ backgroundColor: isSaveButtonEnablednextotp ? '#02CD8D' : '#EAEAEA', color: isSaveButtonEnablednextotp ? '#FFF' : '#333' , cursor: isSaveButtonEnablednextotp ? 'pointer' : 'not-allowed' }} disabled={!isSaveButtonEnablednextotp} className={`profilesave`}>
                  Save
                </button>
              </div>
                </div>
            </div>
            </>
        )}
        {recoveryphonecode &&(
          <>
          <div className="name_div1">
            <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={backs} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">Confirmation Code</h5>
            </div>
            <div className="name-input name-inputimage name-inputimageemail ">
            <div>
                  <label htmlFor="inputField"  className="form-label" style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#000000'}}>Verification Code</label>
                  <input type="text" value={otp} onChange={handleChange} maxLength={6} onPaste={onPaste1} onKeyPress={handleInputChangeotp} className={`form-control docform ${otp ? 'docform-active' : ''}`}  id="inputField" placeholder="Enter Verification Code" />
                </div>
                <div className="error-message error-messageprofile">{error422}</div>
                <div className="name_button_div">
                <button type="button" onClick={confirmcancel} className="me-3  profilecancel">
                  Cancel
                </button>
                <button type="button" onClick={confirmcode} style={{ backgroundColor: isSaveButtonEnablednext ? '#02CD8D' : '#EAEAEA', color: isSaveButtonEnablednext ? '#FFF' : '#333' , cursor: isSaveButtonEnablednext ? 'pointer' : 'not-allowed' }} disabled={!isSaveButtonEnablednext} className={`profilesave`}>
                  Save
                </button>
              </div>
            </div>
            </div>
          </>
        )} 
        </div>
        </div>
    </>
  );
}
export default WithToast(Recoveryphone);