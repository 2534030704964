import React, { useEffect, useRef } from "react";
import back from '../../../images/arrow-left-01.png';
import searchedlocationicon from '../../../images/address-icons/Group 1858.png';
import { APIProvider, Map, Marker, useMap } from '@vis.gl/react-google-maps';

const MapHandler = ({ place, marker }) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !place || !marker) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry.viewport);
    }

    marker.setPosition(place.geometry?.location);
  }, [map, place, marker]);

  return null;
};

function SearchedLocation ({
  addressComponents,
  cameraProps,
  markerPosition,
  Change,
  selectedPlace,
  confirmAndProceed,back1,googlemapkey,handleCameraChange
}) {
  const markerRef = useRef(null);

  const { street, city, state, country, zipcode } = addressComponents;

  return (
    <>
      <div className="address_divhalf1 d-flex flex-column justify-content-between">
        <div>
          <div className="d-flex align-items-center">
            <img src={back} onClick={back1} className="me-2" style={{cursor:'pointer'}} alt="back" width="17px" height="14px" />
            <h6 className="addaddressh6">Add Address</h6>
          </div>
          <div>
            <p className="addaddressSearchp">Confirm your address</p>
            <div className='combinedaddressdiv'>
              <div className="d-flex align-items-center justify-content-between w-100">
                <div className="combinedaddressdivflex w-100">
                  <img src={searchedlocationicon} className='searchedlocationimage' alt='searchlocate' ></img>
                  <div>
                    {street && <p className="confirmaddressp">{street},</p>}
                    {(city || state) && <p className="confirmaddressp">{`${city}${state ? ` - ${state}` : ''}`},</p>}
                    {(country || zipcode) && <p className="confirmaddressp">{`${country}${zipcode ? ` - ${zipcode}` : ''}`}</p>}
                  </div>
                </div>
                <button onClick={Change} className="combinedaddressdivflexbuuton">Change</button>
              </div>
            </div>
          </div>
        </div>
        <button onClick={confirmAndProceed} className="combinedaddressdivconfirmButtonprceed">Confirm & Proceed</button>
      </div>
      <div className="address_divhalf2">
        <APIProvider apiKey={googlemapkey} solutionChannel="GMP_devsite_samples_v3_rgmbasicmap">
          <Map {...cameraProps}  onCameraChanged={handleCameraChange} gestureHandling="greedy">
            {markerPosition && <Marker position={markerPosition} ref={markerRef} />}
          </Map>
          <MapHandler place={selectedPlace} marker={markerRef.current} />
        </APIProvider>
      </div>
    </>
  );
}

export default SearchedLocation;
