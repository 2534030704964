import React, { useEffect, useRef,useState,useCallback} from "react";
import back from '../../../images/arrow-left-01.png';
import searchedlocationicon from '../../../images/address-icons/Group 1858.png';
import { APIProvider, Map, Marker, useMap } from '@vis.gl/react-google-maps';
import Select, { components } from 'react-select';
import { countrystyle, selectWidth, validateAndSetMobileChange } from "../../../api/utils/utils";
import useMenuHeight from "../../../api/hooks/useMenuHeight";
import WithToast from "../../../api/hooks/withtoasthook";
const MapHandler = ({ place, marker }) => {
  const map = useMap();
  useEffect(() => {
    if (!map || !place || !marker) return;
  
    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry.viewport);
    }
  
    if (place.geometry?.location) {
      marker.setPosition(place.geometry.location);
    }
  }, [map, place, marker]);
}  

function SearchedLocationconfirm({
  addressComponents,
  Change,
  cameraProps,
  markerPosition,
  selectedPlace,
  back2,
  googlemapkey,
  confirmAndProceed1,
  formValues,
  setFormValues,
  error,setError,handleCameraChange,countryData,setMobileChange,mobileChange,
}) {
  const {
    dialCode,
    countryCode,
    countryOptions,
    mobile_length,
    mobile_starting_digits,
    isLoading,
    loadCountryByIp,
    setMccId,
    setDialCode,
    setCountryCode,
    setMobile_length,
    setMobile_starting_digits,
  } = countryData;
  const [errormobile, setErrormobile] = useState('');
  const [numberinvalid,setNumberinvalid] = useState(false);
  const handlePhoneNumberChange = (value) => {
    validateAndSetMobileChange(value, {
      setMobileChange: setMobileChange,
      setError: setErrormobile,
      setNumberInvalid: setNumberinvalid,
      mobile_length: mobile_length,             
      mobile_starting_digits: mobile_starting_digits 
    });
  };  
  const handlePaste = (e) => {
      const pastedText = e.clipboardData.getData('text');
      let numericPastedText = pastedText.replace(/[^0-9]/g, '');
      const dialCodeWithoutPlus = dialCode.replace('+', '');
      if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
          numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
      }
      validateAndSetMobileChange(numericPastedText, {
        setMobileChange: setMobileChange,
        setError: setErrormobile,
        setNumberInvalid: setNumberinvalid,
        mobile_length: mobile_length,             
        mobile_starting_digits: mobile_starting_digits 
      });
      e.preventDefault();
  };
  useEffect(()=>{
   loadCountryByIp(true);
  },[]);  
  const handleCountryChange = (selectedOption) => {
      const selectedCountryData = countryOptions.find(
          (country) => country.value === selectedOption.value
      );
      if (selectedCountryData) {
        const { mcc_id, dial_code, country_code, mobile_length, mobile_starting_digits } = selectedCountryData;
    
        setMccId(mcc_id);
        setDialCode(`+${dial_code}`);
        setCountryCode(country_code);
        setMobile_length(mobile_length);
        setMobile_starting_digits(mobile_starting_digits);
    
        // Apply validation to existing mobile number
        validateAndSetMobileChange(mobileChange, {
          setMobileChange,
          setError: setErrormobile,
          setNumberInvalid: setNumberinvalid,
          mobile_length,
          mobile_starting_digits,
        });
      }
  };
 
  const handleKeyPress = (e) => {
    // Allow only numeric characters (0-9) and some specific control keys
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];

    if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };
const customSingleValue = ({ data }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <img
      src={data.flag}
      alt=""
      style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
    />
    {/* {data.label} */}
  </div>
);

const customOption = (props) => {
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={props.data.flag}
          alt=""
          style={{ width: '25px', height: 'auto', marginRight: '10px' }}
        />
        {props.data.label}
      </div>
    </components.Option>
  );
};  


    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value
      }));
    };
    const handleKeyDown = (e) => {
      // Regular expression to allow only alphabets
      const regex = /^[a-zA-Z\s]*$/;
      
      // Check if the input character is valid
      if (!regex.test(e.key)) {
        e.preventDefault();
        setError('Only alphabets are allowed')
      } else {
        setError('')
      }
    };
  const markerRef = useRef(null);
  useEffect(() => {
    validateAndSetMobileChange(mobileChange, {
      setMobileChange,
      setError: setErrormobile,
      setNumberInvalid: setNumberinvalid,
      mobile_length,
      mobile_starting_digits
    });
  }, [mobileChange, mobile_length, mobile_starting_digits]);
  const { street, city, state, country, zipcode } = addressComponents;
  const [checkbutton,setCheckbutton]=useState(false);
  const checkFormValidity = useCallback(() => {
    // Check if city or postcode has values
    const isCityValid = formValues.city !== '' || city !== '';
    const isPostcodeValid = formValues.postcode !== '' || zipcode !== '';
    
    // Validate address type
    const addressTypeIsValid = formValues.addressType === 'Others'
      ? formValues.otherType.trim() !== ''
      : formValues.addressType !== '';
  
    // Final validation check
    const isFormValid =
      isCityValid &&
      isPostcodeValid &&
      addressTypeIsValid &&
      mobileChange !== '' &&
      formValues.houseNumber !== '' &&
      numberinvalid === true &&  // Assuming 'numberinvalid' is 'false' when valid
      formValues.addressLine2 !== '';
  
    setCheckbutton(isFormValid);
  }, [
    formValues.city,
    formValues.postcode,
    mobileChange,
    formValues.houseNumber,
    formValues.addressLine2,
    formValues.addressType,
    formValues.otherType,
    city,
    zipcode,
    numberinvalid
  ]);
  
  // Run validity check when form values change
  useEffect(() => {
    checkFormValidity();
  }, [checkFormValidity]);
  const [selwidth, setSelWidth] = useState(selectWidth(dialCode));
  const menuHeight = useMenuHeight(200, 400);

  // Update the selection width whenever dialCode changes
  useEffect(() => {
    setSelWidth(selectWidth(dialCode));
  }, [dialCode]);
  return (
    <>
      <div className="address_divhalf1 address_divhalf1confirm d-flex flex-column justify-content-between">
        <div>
          <div className="d-flex align-items-center">
            <img src={back} onClick={back2} className="me-2" style={{cursor:'pointer'}} alt="back" width="17px" height="14px" />
            <h6 className="addaddressh6">Add Address</h6>
          </div>
          <div>
            <p className="addaddressSearchp">Confirm your address</p>
            <div className='combinedaddressdiv mb-3'>
              <div className="d-flex align-items-center justify-content-between w-100">
                <div className="combinedaddressdivflex w-100">
                  <img src={searchedlocationicon} className='searchedlocationimage' alt='searchlocate' ></img>
                  <div>
                    {street && <p className="confirmaddressp">{street},</p>}
                    {(city || state ) && <p className="confirmaddressp">{`${city || formValues.city}${state ? ` - ${state}` : ''}`},</p>}
                    {(country || zipcode) && <p className="confirmaddressp">{`${country}${zipcode || formValues.postcode ? ` - ${zipcode || formValues.postcode}` : ''}`}</p>}
                  </div>
                </div>
                <button onClick={Change} className="combinedaddressdivflexbuuton">Change</button>
              </div>
            </div>
            <div className='combinedaddressdiv mb-3'>
            <label className="combinedaddressdivlabel">Name</label>
            <input type="text"  name="Name" className="form-control combinedaddressdivlabelinput" value={formValues.Name} onKeyDown={handleKeyDown} onChange={handleInputChange}></input>
            {error && <p className="error-message">{error}</p>}
            <label className="combinedaddressdivlabel">Mobile Number</label>
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center',height:'36px',top:'-3px' }}>
            <div style={{ position: 'absolute', left: 0, zIndex: 1,top:'-3px' }}>
              <Select options={countryOptions} name='countrycode' onChange={handleCountryChange} value={countryOptions.find(option => option.value === countryCode)} components={{ SingleValue: customSingleValue, Option: customOption }} placeholder="" isLoading={isLoading} styles={countrystyle('82px',menuHeight)}/>
            </div>
            <div style={{ height: '30px', width: '2px', backgroundColor: '#ccc', margin: '0 8px', position: 'absolute', left: `${selwidth}px`, top: '50%', transform: 'translateY(-50%)' }}></div>
            <p style={{ position: 'absolute', left:'78px', top: '45%', transform: 'translateY(-50%)', cursor: 'pointer',color:'hsl(0, 0%, 20%)',fontSize:'14px',lineHeight:'1.5',marginBottom:'0px',fontWeight:'400' }}>{dialCode}</p>
            <input
              type="text"
              className={`form-control combinedaddressdivlabelinput`}
              id="mobile" name='mobileNumber'  style={{ paddingLeft: `${selwidth + 18}px`, width: '100%' }} placeholder="Mobile Number" value={mobileChange} onPaste={(e) => handlePaste(e)} onKeyPress={handleKeyPress} onChange={(e) => handlePhoneNumberChange(e.target.value)} />
          </div>
          {errormobile && <div className="error-message">{errormobile}</div>}
            {!city && (
            <>  
           <label className="combinedaddressdivlabel">City</label>
           <input type="text"  name="city" className="form-control combinedaddressdivlabelinput" value={formValues.city} onChange={handleInputChange}></input>
           </>
            )}
            {!zipcode && (
            <>
           <label className="combinedaddressdivlabel">postal Code</label>
           <input type="text"  name="postcode" onKeyPress={handleKeyPress} className="form-control combinedaddressdivlabelinput" value={formValues.postcode} onChange={handleInputChange}></input>
           </>
            )}
             <label className="combinedaddressdivlabel">House/Flat/Block No</label>
             <input type="text"  name="houseNumber" className="form-control combinedaddressdivlabelinput" value={formValues.houseNumber} onChange={handleInputChange}></input>
             <label className="combinedaddressdivlabel">Apartment/Road/Area(Optional)</label>
             <input type="text" name="apartment" className="form-control combinedaddressdivlabelinput" value={formValues.apartment} onChange={handleInputChange}></input>
             <label className="combinedaddressdivlabel">Address Line 2</label>
             <input type="text"  name="addressLine2" className="form-control combinedaddressdivlabelinput" value={formValues.addressLine2} onChange={handleInputChange}></input>
             {/* <BusinessModuleSelect  formValues={formValues} setFormValues={setFormValues}/> */}
             {/* <Select
  options={formValues.moduleoption}
  value={formValues.moduleoption.filter(option => formValues.module.includes(option.value))}
  onChange={handleModulesChange}
  isMulti
  isDisabled={isDisabled} 
  styles={addressStyles1('36px', '#EFF3F4', '6px', '5px')}
/> */}

             <div className="address-type-container">
      <label className={`address-type ${formValues.addressType === 'Others' ? 'd-none' : ''}`}>
        <input
          type="radio"
          name="addressType"
          value="Home"
          checked={formValues.addressType === 'Home'}
          onChange={handleInputChange}
        />
        Home
      </label>
      <label className={`address-type ${formValues.addressType === 'Others' ? 'd-none' : ''}`}>
        <input
          type="radio"
          name="addressType"
          value="Office"
          checked={formValues.addressType === 'Office'}
          onChange={handleInputChange}
        />
        Office
      </label>
      <label className={`address-type ${formValues.addressType === 'Others' ? 'd-none' : ''}`}>
        <input
          type="radio"
          name="addressType"
          value="Friends"
          checked={formValues.addressType === 'Friends'}
          onChange={handleInputChange}
        />
        Friends
      </label>
      <label className="address-type">
        <input
          type="radio"
          name="addressType"
          value="Others"
          checked={formValues.addressType === 'Others'}
          onChange={handleInputChange}
        />
        Others
      </label>
      {
        formValues.addressType === 'Others' ? <div style={{position:'relative',width:'100%'}}><input type="text" name="otherType" value={formValues.otherType} onChange={handleInputChange} className="form-control combinedaddressdivlabelinput"></input><p onClick={() => setFormValues((prev) => ({ ...prev, otherType: '',addressType:'' }))} style={{position: 'absolute', marginRight: '15px',right:'0px', top: '45%', transform: 'translateY(-50%)', cursor: 'pointer',fontWeight:'400',fontSize:'14px',lineHeight:'18px',color:'#F77F00'}}>Cancel</p></div>: ""
      }
    </div>
            </div>
          </div>
        </div>
        <button  className={`combinedaddressdivconfirmButtonprceed ${!checkbutton ? 'combinedaddressdivconfirmButtonprceeddisabled' : 'combinedaddressdivconfirmButtonprceed'}`} disabled={!checkbutton} onClick={confirmAndProceed1}>Confirm & Proceed</button>
      </div>
      <div className="address_divhalf2">
        <APIProvider apiKey={googlemapkey} solutionChannel="GMP_devsite_samples_v3_rgmbasicmap">
          <Map {...cameraProps} onCameraChanged={handleCameraChange} gestureHandling="greedy">
            {markerPosition && <Marker position={markerPosition} ref={markerRef} />}
          </Map>
          <MapHandler place={selectedPlace} marker={markerRef.current} />
        </APIProvider>
      </div>
    </>
  );
}

export default WithToast(SearchedLocationconfirm);
