import React, { useState, useEffect } from "react";
import logo from '../images/evzone-logo-01.png';
import { useNavigate } from "react-router-dom";
import time from '../images/Group.png';
import { UseToast } from "../api/hooks/toasthook";
import plus1 from "../images/plus (1).png";
import axios from "axios";
import '../transactions.css';
import { refreshapi, sidapi, publicDomain } from "../api/helper/urlhelper";
import { getQueryParameter, handleApiError, redirectToWithAuthUser } from "../api/utils/utils";
import '../App.css';
const refreshapiurl = refreshapi();
const sidapiurl = sidapi();
const publicUrl = publicDomain();

function Refreshpage() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const authuser = urlParams.get('X-Authuser');
  const [isLoading4,setIsLoading4] = useState(false);
  const [items, setItems] = useState(["back1", "back2", "back3", "back4", "back5"]);
  const [designchanges, setDesignchanges] = useState(true);
  const [designchangesswitch, setDesignchangesswitch] = useState(false);
  const [useraccounts, setUseraccounts] = useState([]);
  const [error422,setError422] = useState('');
  const { is422ErrorToastActive, showErrorToast, showSuccessToast } = UseToast();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const autoplayInterval = 15000;
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setItems((prev) => [...prev, prev[currentIndex]]);
    setCurrentIndex(currentIndex + 1);
  };

  useEffect(() => {
    const intervalId = setInterval(nextSlide, autoplayInterval);

    return () => clearInterval(intervalId);
  }, [currentIndex]);
  const handleAddAccount = (event) => {
    event.preventDefault();
    navigate('/');
  }
  const handleSwitchAccount = async (event) => {
    event.preventDefault();
    setDesignchanges(false);
    setDesignchangesswitch(true);
    try {
      setIsLoading4(true);
      const headers = {
        'Content-Type': 'application/json',
      }
      const response = await axios.get(sidapiurl, { headers: headers, withCredentials: true });
      if (response.status === 200) {
        const accounts = response.data.data;
        setUseraccounts(accounts);
        setIsLoading4(false);
      }
    } catch (error) {
      handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
    }
  }

  const resume = async (event) => {
    event.preventDefault();
      if (!password) {
        setError422('Please Enter Password');
        return false;
      }else{
        setError422('');
      }
      const data = {
        password: password
      }
      try {
        setIsLoading4(true);
      const headers = {
        'Content-Type': 'application/json',
        'X-Authuser': authuser,
      }
      const response = await axios.post(refreshapiurl, data, { headers: headers, withCredentials: true });
      if (response.status === 200) {
        setError422('');
        const continueparameter = getQueryParameter('continue');
        const continueparameterauth = getQueryParameter('x-Authuser');
        if (continueparameter && continueparameterauth) {
          setIsLoading4(false);
          redirectToWithAuthUser(continueparameter, continueparameterauth);
        } else {
          // makeAjaxCall();
          navigate(`/u/${authuser}/services`);
          setIsLoading4(false);
        }
      }
    } catch (error) {
      handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
    }
  }
  const selectedaccount = (event,item) => {
      event.preventDefault();
        // makeAjaxCall();
        navigate(`/u/${item.authuser}/services`);
  }
  return (
    <>
    {isLoading4 && (
      <>   
          <div className="loaderover">
          <div className="loader ">
          </div>
          </div>
      </>
  )} 
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      <div className="slider-wrapper">
        <div
          className="slider-content"
          style={{
            transform: `translateX(-${((currentIndex + 1) * 100)}%)`,
            transition: "transform 0.5s ease-in-out",
            willChange: "transform",
            width: '100%',
          }}
        >
          {items.map((item, index) => (
            <div key={index} className={`bannerimg ${item}`}></div>
          ))}
        </div>
        <div className="refresh_div">
          <img src={logo} alt="logo" className="logo" />
          {designchanges && (
            <form className="refresh_form ">
              <div className="refresh_div1 refresh_div1scroll">
                <img src={time} className="timegrouop" alt="timeout icon" />
                <h6 className="refreshh6 mt-1">Your session has timed out</h6>
                <p className="refreshp">Please input your password to resume progress</p>
                <div className="refreshdivpass">
                  <label className="refreshpasslabel">Password</label>
                  <input
                    value={password}
                    onChange={(e) => {setPassword(e.target.value);setError422('')}}
                    className="form-control mt-1 refreshinput"
                    placeholder="Enter Password"
                    type="password"
                  />
                  <p className="refreshforgot mt-2">Forgot Password?</p>
                  {error422 && (<div className='errors'>{error422}</div>)}
                  <button
                    className="refreshresumebut"
                    onClick={(event) => resume(event)}
                  >
                    Resume
                  </button>
                </div>
              </div>
              <button
                className="refreshswitch mt-3"
                onClick={(event) => handleSwitchAccount(event)}
              >
                Switch Account
              </button>
            </form>
          )}
          {designchangesswitch && (
            <form className="refresh_formswitch">
              <div className="refresh_div1 refresh_div1scroll">
                <h6 className="refreshh6 mt-1">Select Account</h6>
                {useraccounts.filter(account => !account.exp && !account.logout).map((item, index) => (
                  <div key={index} onClick={(event)=>selectedaccount(event,index,item)} className="refreshswictchdiv">
                    <div className="d-flex align-items-center">
                      <div className="refreshuserprofilediv">
                        <img
                          src={`${publicUrl}/core/${item.image}`}
                          alt="user"
                          className="refreshuserprofile"
                        />
                      </div>
                      <div className="ps-3">
                        <h6 className="refreshuserprofileh6">{item.name}</h6>
                        <p className="refreshuserprofilep">{item.email}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {error422 && (<div className='errors'>{error422}</div>)}
              <button
                className="refreshaddaccount mt-3"
                onClick={(event) => handleAddAccount(event)}
              > <img src={plus1} alt="plus" className="me-2" width='10px' height='auto'></img>
                 Account
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
    </>
  )
}

export default Refreshpage;
