import React, { useEffect } from 'react';
import Select from 'react-select';
import { addressStyles1 } from '../../api/utils/utils';

// Helper function to extract `business_module` from the URL query parameters
const getBusinessModulesFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('business_module') ? urlParams.get('business_module').split(',') : [];
};

const BusinessModuleSelectupdate = ({ formValues, setFormValues }) => {
  const businessModulesFromURL = getBusinessModulesFromURL();

  useEffect(() => {
    // Ensure formValues.module is an array
    const currentModules = Array.isArray(formValues.module) ? formValues.module : [];
    if (formValues && formValues.moduleoption && Array.isArray(formValues.moduleoption)) {
      // Find options that match values from URL
      const urlModuleOptions = formValues.moduleoption.filter(option =>
        businessModulesFromURL.includes(option.value)
      );
      const combinedModules = [
        ...currentModules,
        ...urlModuleOptions.filter(urlOption =>
          !currentModules.some(selected => selected?.value === urlOption.value)
        ),
      ];
      if (combinedModules.length !== currentModules.length) {
        setFormValues((prev) => ({
          ...prev,
          module: combinedModules,
        }));
      }
    }
  }, [formValues.moduleoption, formValues.module, setFormValues, businessModulesFromURL]);

  const handleModulesChange = (selectedOptions) => {
    // Preserve URL-based options, disallowing their removal
    const currentModules = Array.isArray(formValues.module) ? formValues.module : [];
    const updatedModules = [
      ...currentModules.filter(option => businessModulesFromURL.includes(option?.value)),
      ...selectedOptions.filter(option => option && !businessModulesFromURL.includes(option.value)),
    ];
    setFormValues((prev) => ({
      ...prev,
      module: updatedModules,
    }));
  };

  return (
    <>
      <label htmlFor="showBusinessModuleCheckbox" className="combinedaddressdivlabel mb-0">Business Module</label>
      <Select
        inputId="showBusinessModuleCheckbox"
        options={formValues.moduleoption}
        value={Array.isArray(formValues.module) ? formValues.module : []}
        onChange={handleModulesChange}
        isMulti
        isOptionDisabled={(option) => businessModulesFromURL.includes(option.value)}
        styles={addressStyles1('36px', '#EFF3F4', '6px', '5px')}
      />
    </>
  );
};

export default BusinessModuleSelectupdate;
