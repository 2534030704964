import { useNavigate } from 'react-router-dom';

const UseCustomNavigate = () => {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);  // Perform the navigation
  };

  return navigateTo;
};

export default UseCustomNavigate;
