import React,{useState,useEffect} from "react";
import skillscertificate from '../images/Acadamic-certificate-02.png';
import popdeletes from '../images/general-id/delete-01.png';
import skillimg from '../images/skills-01.png';
import Modal from 'react-bootstrap/Modal';
import plus1 from '../images/plus.png';
import closecircle from '../images/times-circle-01.png';
import { handleApiError } from "../api/utils/utils";
import { skilldeleteapi,skillgetapi,skilllistapi,skillstoreapi } from "../api/helper/urlhelper";
import axiosInstance from "../api/utils/axiosutils";
// import { UseToast } from "../api/common/toastcontext";
import Select from 'react-select';
import Swal from 'sweetalert2';
import SkeletonLoader from "./skeletonloader";
import WithToast from "../api/hooks/withtoasthook";
const skilldeleteapiurl = skilldeleteapi();
const skillgetapiurl = skillgetapi();
const skilllistapiurl = skilllistapi();
const skillstoreapiurl = skillstoreapi();
function Skills({documentRef,showErrorToast}){
    // const {showErrorToast,showSuccessToast } = UseToast();
    const [skilllists , setSkilllists] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [skillsget, setSkillsget] = useState([]);
    const [error422,setError422] = useState('');
    const [isLoading4,setIsLoading4] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [skillcheck,setSkillcheck] = useState(false);
    const [show5, setShow5] = useState(false);
    const fetchskilllist = async () => {
      try{
        // const token = localStorage.getItem('access_token');
        // const headers = {
        //   'Authorization': `Bearer ${token}`,
        //   'Content-Type': 'application/json',
        // } 
      const response = await axiosInstance.get(skilllistapiurl,);
      if(response.status === 200){
        const skilllist = response.data.data.values;
        setSkilllists(skilllist);
      }
      }catch(error){
        handleApiError(error,setIsLoading1,null,true,showErrorToast);
      }
    }
    const skillget = async() => {
      try{
        setIsLoading1(true);
        // const token = localStorage.getItem('access_token');
        // const headers = {
        //   'Authorization': `Bearer ${token}`,
        //   'Content-Type': 'application/json',
        // }
        const response = await axiosInstance.get(skillgetapiurl,);
        if(response.status === 200){
          setIsLoading1(false);
          const skilget = response.data.data;
          setSkillsget(skilget);
        }
      }catch(error){
        handleApiError(error,setIsLoading1,null,true,showErrorToast);
      }
    }
    useEffect(()=>{
        skillget();
    },[]);
    const checkskillFormValidity = () => {
      const isValid = (
         selectedSkills.length > 0 
      );
      setSkillcheck(isValid);
    };
    useEffect(()=>{
      checkskillFormValidity();
    },[selectedSkills])
    // useEffect(()=>{
    //   skillget();
    // },[]);
    const addskill = () => {
      setShow5(true);
      fetchskilllist();
    }
    const handleClose5 = () => {
      setShow5(false);
      setSelectedSkills([]);
    }
    const close5 = () => {
      handleClose5();
    }
    const skillclose = () => {
      handleClose5();
    }
    const skillstore = async () => {
      try{
        // const token = localStorage.getItem('access_token');
        // const headers = {
        //   'Authorization': `Bearer ${token}`,
        //   'Content-Type': 'application/json',
        // }
        setIsLoading4(true);
        const skillIds = selectedSkills.map(skill => skill.value);
        const data = {
          skill_ids : skillIds
        } 
          const response = await axiosInstance.post(skillstoreapiurl,data,);
          if(response.status === 200){
            setShow5(false);
            skillget();
            handleClose5();
            setIsLoading4(false);
          }
      }catch(error){
        handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
      }
    }
    const skilldelete = async(skill) => {
      try {
        // const token = localStorage.getItem('access_token');
        // const headers = {
        //   'Authorization': `Bearer ${token}`,
        //   'Content-Type': 'application/json',
        // };
        const id = skill.id;
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        });
        
  
        if (result.isConfirmed) {
          setIsLoading4(true);
          const response = await axiosInstance.post(`${skilldeleteapiurl}?id=${id}`, {},);
          if (response.status === 200) {
            await skillget();
            // showSuccessToast(response.data.message,'422Error',);
            setIsLoading4(false);
          }
        }
      }
      catch (error) {
        handleApiError(error,setIsLoading4,null,true,showErrorToast);
      }
    }
    return(
        <>
        {isLoading4 && (
                <>   
                    <div className="loaderover">
                    <div className="loader ">
                    </div>
                    </div>
                </>
            )}
      {skillsget.length > 0 ? (
        skillsget.map((skill, index) => (
          <div key={index} className="docbox">
            <div className="d-flex">
              <div style={{ width: "80%" }}>
                <div className="skilldiv">
                  <img src={skillscertificate} alt="car" className="docskillimg"></img>
                  <h6 className="skillh6">{skill.skill.name}</h6>
                </div>
              </div>
              <div className="skill20">
                <img
                  src={popdeletes}
                  onClick={() => skilldelete(skill)}
                  style={{ cursor: "pointer" }}
                  alt="delete"
                  width="25px"
                  height="auto"
                ></img>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="d-flex mt-5 justify-content-center align-items-center flex-column generalnew">
          {isLoading1 ? (
            <>
            <SkeletonLoader documentRef={documentRef}/>
            </>
          ) : (
            <>
              <img src={skillimg} alt="document" className="docorangeimage"></img>
              <h6 className="addnewgeneralh6 mt-2">Add Skills</h6>
              <p className="generalp">Tell us about current or previous employment, add any additional details like certificates you earned</p>
              <button className="addnewgeneralbutton" onClick={addskill}>Add Skills</button>
            </>
          )}
        </div>
      )}

      {skillsget.length > 0 && (
        <div className="text-end mt-3" style={{ width: "97%" }}>
          <button className="docaddnew" onClick={addskill}>
            <img
              src={plus1}
              className="me-2"
              alt="plus"
              width="15px"
              height="auto"
            ></img>
            New
          </button>
        </div>
      )}

<Modal show={show5} dialogClassName="document-dialog1" contentClassName="document-content1" onHide={handleClose5} centered>
        <Modal.Body style={{ margin: '0', padding: '0' }}>
          <div className="d-flex align-items-center justify-content-between docpop">
            <h6 className="docpoph6">Add Skills</h6>
            <img src={closecircle} onClick={close5} alt="close" className="docpopclose"></img>
          </div>
          <div className="error-message1">{error422}</div>
           <div>
            <div className="mt-3 mb-2">
            <label className="docpoplabel1 mb-2">Search Skills</label>  
           <Select
            options={skilllists.map(skill => ({ value: skill.id, label: skill.name }))}
            isMulti
            closeMenuOnSelect={false}
            value={selectedSkills}
            onChange={setSelectedSkills}
            placeholder="Select your skill"
          />
          </div>
            <div className="d-flex justify-content-end align-items-center mt-4 mb-2">
              <button className="docpopcancel me-3" onClick={skillclose}>Cancel</button>
              <button className="docpopsave" style={{backgroundColor: skillcheck ? '#5D64F2' : '#EAEAEA', color: skillcheck ? '#FFF' : '#333' , cursor: skillcheck ? 'pointer' : 'not-allowed' }} disabled={!skillcheck} onClick={skillstore}>Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
    );
}
export default WithToast(Skills);