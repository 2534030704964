// userActions.js
import { publicDomain, profileapi, languagegetapi,} from '../../helper/urlhelper';
import axiosInstance from "../../utils/axiosutils";
import { handleApiError } from "../../utils/utils";
const publicUrl = publicDomain();
const profileUrl =profileapi();
const languagesgetapiurl = languagegetapi();
export const FETCH_USER_PROFILE_REQUEST = 'FETCH_USER_PROFILE_REQUEST';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE';
export const FETCH_USER_LANGUAGE_REQUEST = 'FETCH_USER_LANGUAGE_REQUEST';
export const FETCH_USER_LANGUAGE_FAILURE = 'FETCH_USER_LANGUAGE_FAILURE';
export const FETCH_USER_LANGUAGE_SUCCESS = 'FETCH_USER_LANGUAGE_SUCCESS';

  export const fetchUserProfileRequest = () => ({
  type: FETCH_USER_PROFILE_REQUEST,
  });

  export const fetchUserProfileSuccess = (userProfile) => ({
  type: FETCH_USER_PROFILE_SUCCESS,
  payload: userProfile,
  });

  export const fetchUserProfileFailure = (error) => ({
  type: FETCH_USER_PROFILE_FAILURE,
  payload: error,
  });

  export const fetchUserLanguageRequest = () => ({
  type: FETCH_USER_LANGUAGE_REQUEST,
  });

  export const fetchUserLanguageSuccess = (userLanguage) => ({
  type: FETCH_USER_LANGUAGE_SUCCESS,
  payload: userLanguage,
  });

  export const fetchUserLanguageFailure = (error) => ({
  type: FETCH_USER_LANGUAGE_FAILURE,
  payload: error,
  });

  export const fetchUserProfile = (showErrorToast) => {
    return async (dispatch) => {
      dispatch(fetchUserProfileRequest()); 
      try {  
        const response = await axiosInstance.get(profileUrl);
     
        if (response.status === 200) {
          const data = response.data.data;
          dispatch(fetchUserProfileSuccess({
            is_default : data.image_status,
            image: `${publicUrl}/core/${data.image}`,
            default_profile:`${publicUrl}/core/${data.default_profile}`,
            name: data.name,
            dob: data.dob,
            gender: data.gender,
            email: data.email,
            code:data.mcc.code,
            mobile: data.mobile,
            mcc_id: data.mcc_id,
            mcc_countrycode: data.mcc.country_code,
            dob_visibility : data.dob_visibility,
            profile_image_visibility:data.profile_image_visibility,
            gender_visibility:data.gender_visibility
          }));
        }
      } catch (error) {
        handleApiError(error, null, null, true, showErrorToast);
        dispatch(fetchUserProfileFailure(error));
        }
    };
  };
  export const fetchUserLanguage = (showErrorToast) => {
    return async (dispatch) => {
      dispatch(fetchUserLanguageRequest());
  
      try {  
        const response = await axiosInstance.get(languagesgetapiurl);
        if (response.status === 200) {
          const data = response.data.data;
          dispatch(fetchUserLanguageSuccess({
            data
          }));
        }
      } catch (error) {
        handleApiError(error, null, null, true, showErrorToast)
        dispatch(fetchUserLanguageFailure(error));
      }
    };
  };
  
 