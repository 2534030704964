import React ,{useState,useEffect} from "react";
import user from "../images/user-3.jpg";
import { useDispatch , useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import eye from '../images/eye-01.png';
import eyeslash from '../images/eye_slash-01.png';
import Navbars from "../navbar";
import eyegrey from '../images/eye-01pass.png';
import eyeslashgrey from '../images/eye-slash-01passw.png';
import leftarrow from '../images/arrow-left-01.png';
import { fetchUserProfile } from "../api/redux/userProfiles/actions";
import { extractLoginUser,handleApiError } from "../api/utils/utils";
function Twofactor() {
  const navigate = useNavigate();
  const login_user = extractLoginUser();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userProfile.userProfile); // Use useSelector to access userProfile from Redux state
  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);
  const [showPassword, setShowPassword] = useState(false);
  const [password , setPassword] = useState('');
  const [error422, setError422] = useState('');
  const toggleVisibility = () => {
    setShowPassword(!showPassword);
  }
  const goBack = () => {
    navigate(`/u/${login_user}/security`);
  }
  const cancelPasswordChange = () => {
    navigate(`/u/${login_user}/security`);
  }
  const goToNextStep = () => {
    navigate(`/u/${login_user}/twofactorauth`);
  }
  return (
    <>
     <div>
        <div className="container-fluid" style={{ height: '100px' }}>
          <Navbars />
        </div>
        <div className="name_div">
        <div className="name_div1">
          <div>
            <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={goBack} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">Password</h5>
            </div>
            <div className="name-input name-inputimage">
            <div className="header-profile-user border-dark user mx-auto " style={{ width: '80px', height: '80px', borderRadius: '50%', overflow: 'hidden' }}>
            <img id="login_img_for_profile" src={userProfile.image} alt="Profile" loading="lazy"className=" homeprofile mb-3" style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}/></div>
            <p style={{ fontWeight: '500', fontSize: '20px', lineHeight: '30px', color: '#292929' }}>{userProfile.name}</p>
            <div className="text-start" style={{width:'100%'}}>
                <label htmlFor="password" className="mb-2" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Current Password</label>
              <div className="password " style={{ position: 'relative' }}>
                <input type={showPassword ? 'text' : 'password'} value={password} onChange={(e)=>{setPassword(e.target.value);setError422('')}} className={`form-control docform ${password ? 'docform-active' : ''}`}  id="userpassword" placeholder="Enter Your Current Password" name="password"/>
                <p className="toggle-password" onClick={toggleVisibility} style={{ position: 'absolute', marginRight: '15px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                  {showPassword ? (
                    password ? (
                    <img src={eye} className='eye' width='19.5px' height='19.5px' alt="Show Password"/>
                    ) : (
                      <img src={eyegrey} className='eye' width='19.5px' height='19.5px' alt="Show Password"/>
                    )
                  ) : (
                    password ? (
                    <img src={eyeslash} className='eye' width='19.5px' height='19.5px' alt="Hide Password" />
                    ) : (
                      <img src={eyeslashgrey} className='eye' width='19.5px' height='19.5px' alt="Hide Password" />
                    )
                  )}
                </p>
              </div>
              </div>
              <div className="error-message error-messageprofile">{error422}</div>
              <div className="name_button_div" style={{width:'100%'}}>
                <button type="button" onClick={cancelPasswordChange} className="profilecancel me-4">
                  Cancel
                </button>
                <button type="button" onClick={goToNextStep} className={`profilesave`}>
                  Next
                </button>
              </div>
            </div>
            </div>
            </div>
            </div>
      </div>  
      {/* <Navbars />
      <div className="container-fluid passwordchange">
        <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="col-lg-7 col-md-8 col-sm-10">
            <div className="box p-5" style={{ backgroundColor: 'white', width: '100%', maxWidth: '620px', minHeight: '424px', borderRadius: '18px', border: '1px solid #ABA7A7' }}>
              <div className="d-flex align-items-center">
                <img onClick={goBack} src={leftarrow} alt="leftarrow" width='19.5px' height='15.5px' className="mb-3 me-4" style={{ cursor: 'pointer' }} />
                <h5 className='mb-3' style={{ fontWeight: '500', fontSize: '24px', lineHeight: '36px', color: '#232323' }}>Password</h5>
              </div>
              <div className="d-flex flex-column align-items-center">
                <img src={user} className="rounded-circle header-profile-user border-dark user img-fluid mx-auto d-block mb-3" style={{ maxWidth: '80px', height: '80px' }} alt="Right 3" />
                <p style={{ fontWeight: '500', fontSize: '20px', lineHeight: '30px', color: '#292929' }}>John Doe</p>
              </div>
              <div className="d-flex justify-content-start mb-3 ps-5">
                <label htmlFor="password" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Current Password</label>
              </div>
              <div className="password mb-3 ps-5" style={{ position: 'relative' }}>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control passwordchangeinputbox"
                  id="userpassword"
                  placeholder="***********"
                  name="password"
                />
                <p className="toggle-password" onClick={toggleVisibility} style={{ position: 'absolute', marginRight: '15px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                  {showPassword ? (
                    <img src={eye} className='eye' width='19.5px' height='19.5px' alt="Show Password"/>
                  ) : (
                    <img src={eyeslash} className='eye' width='19.5px' height='19.5px' alt="Hide Password" />
                  )}
                </p>
              </div>
              <div className="row">
                <div className="col-6 ps-5">
                  <p className="forgot" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#02CD8D',marginLeft:'20px' }}>Forgot Password ?</p>
                </div>
                <div className="col-6 text-md-end">
                  <div className="d-flex align-items-center justify-content-md-end  mx-md-2 d-block">
                    <button onClick={cancelPasswordChange} type="button" className=" profilecancel me-4">Cancel</button>
                    <button onClick={goToNextStep} type="button" className=" profilesave">Next</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
export default Twofactor;