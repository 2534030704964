// import { mcc_list_ip } from "./apihandle";
// import { useIpContext } from "./ipContext";
// import { handleApiError } from "./commonapi";

// const useCountryData = (showErrorToast) => {
//   const { ipCountryCode, getUserIpCountryCode } = useIpContext();

//   const fetchCountryData = async (setMccId, setDialCode, setCountryCode,setCountryOptions,countryCode) => {
//     try {
//       const res = await mcc_list_ip();
//       const mcc_list = res.data.data;

//       const codes = mcc_list.map((mcc) => ({
//         country_code: mcc.country_code,
//         dial_code: mcc.code,
//         mcc_id: mcc.id,
//         label: `${mcc.name} (+${mcc.code})`,
//         value: mcc.country_code,
//         flag: `https://flagcdn.com/w320/${mcc.country_code.toLowerCase()}.png`,
//       }));
//       setCountryOptions(codes); 
//       const selectedCountryData = codes.find(
//         (country) => country.country_code === countryCode
//       );

//       if (selectedCountryData) {
//         setMccId(selectedCountryData.mcc_id);
//         setDialCode(`+${selectedCountryData.dial_code}`);
//         setCountryCode(selectedCountryData.country_code);
//       }
//     } catch (error) {
//       handleApiError(error, null, null, true, showErrorToast);
//     }
//   };


//   const loadCountryByIp = async (setMccId, setDialCode, setCountryCode,setCountryOptions) => {
//     await getUserIpCountryCode();
//     fetchCountryData(setMccId, setDialCode, setCountryCode,setCountryOptions,ipCountryCode);
//   };

//   const loadCountryBySelection = (setMccId, setDialCode, setCountryCode,setCountryOptions,selectedCountryCode) => {
//     fetchCountryData(setMccId, setDialCode, setCountryCode,setCountryOptions,selectedCountryCode);
//   };

//   return { loadCountryByIp, loadCountryBySelection };
// };

// export default useCountryData;

// CountryDataContext.js
import React, { createContext, useCallback, useContext, useState,useEffect } from 'react';
import { mcc_list_ip } from "../helper/apiurlhelper";
import { useIpContext } from "../hooks/iphook";
import { extractLengthValues, handleApiError } from "../utils/utils";
import WithToast from './withtoasthook';

const CountryDataContext = createContext();

export const useCountryData = () => {
  return useContext(CountryDataContext);
};
export const CountryDataProvider = WithToast(({ children, showErrorToast }) => {
  const { ipCountryCode, getUserIpCountryCode } = useIpContext();

  
  const [mccId, setMccId] = useState(null);
  const [dialCode, setDialCode] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [shold, setShold] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [mobile_length,setMobile_length] = useState('');
  const [mobile_starting_digits,setMobile_starting_digits] = useState('');
  const [isLoading,setIsLoading] = useState(false);
  const fetchCountryData = useCallback(async(code) => {
    if(!code) return;
    try {
      setIsLoading(true);
      const res = await mcc_list_ip();
      const mcc_list = res.data.data;

      const codes = mcc_list.map((mcc) => ({
        country_code: mcc.country_code,
        dial_code: mcc.code,
        mcc_id: mcc.id,
        label: `${mcc.name} (+${mcc.code})`,
        value: mcc.country_code,
        flag: `https://flagcdn.com/w320/${mcc.country_code.toLowerCase()}.png`,
        mobile_length:mcc.mobile_length,
        mobile_starting_digits:mcc.mobile_starting_digits
      }));
      
      setCountryOptions(codes); 
      const value = code
      const selectedCountryData = codes.find((country) => country.country_code === value);
      if (selectedCountryData) {
        setMccId(selectedCountryData.mcc_id);
        setDialCode(`+${selectedCountryData.dial_code}`);
        setCountryCode(selectedCountryData.country_code);
        setMobile_length(selectedCountryData.mobile_length);
        setMobile_starting_digits(selectedCountryData.mobile_starting_digits);
        const maxLength = extractLengthValues(mobile_length);
      }
    } catch (error) {
      handleApiError(error, null, null, true, showErrorToast);
    }finally{
      setShold(false);
      setIsLoading(false);
    }
  },[showErrorToast,mobile_length,]);
  useEffect(() => {
    if (ipCountryCode && shold) {
      fetchCountryData(ipCountryCode);
    }
  }, [ipCountryCode, fetchCountryData,shold]);
  const loadCountryByIp = async (sholds=true) => {
    setShold(sholds); 
    await getUserIpCountryCode();
  };
  const loadCountryBySelection = (selectedCountryCode) => {
    fetchCountryData(selectedCountryCode);
  };
  const value = {
    mccId, dialCode, countryCode, countryOptions,mobile_length,mobile_starting_digits,isLoading,setMccId,setDialCode,setCountryCode,setMobile_length,setMobile_starting_digits,
      loadCountryByIp,loadCountryBySelection
  }
  return (
    <CountryDataContext.Provider value={value}>
      {children}
    </CountryDataContext.Provider>
  );
});

