// import React, { useState, useEffect } from 'react';
// import Select from 'react-select';
// import { addressStyles1 } from '../../api/utils/utils';

// // Helper function to extract `business_module` from the URL query parameters
// const getBusinessModulesFromURL = () => {
//   const urlParams = new URLSearchParams(window.location.search);
//   return urlParams.get('business_module') ? urlParams.get('business_module').split(',') : [];
// };

// const BusinessModuleSelect = ({formValues,setFormValues}) => {
//   const [isDisabled, setIsDisabled] = useState(false);
//   useEffect(() => {
//     const businessModulesFromURL = getBusinessModulesFromURL();

//     // If business modules from URL match options in formValues.moduleoption
//     const matchedModules = businessModulesFromURL.filter(module =>
//       formValues.moduleoption.some(option => option.value === module)
//     );

//     if (matchedModules.length > 0) {
//       setFormValues((prev) => ({
//         ...prev,
//         module: matchedModules,
//         modulecheckbox: true,
//       }));
//       setIsDisabled(true);
//     } else {
//       setIsDisabled(false);
//     }
//   }, [formValues.moduleoption]);
//   const handleCheckboxChange = () => {
//     setFormValues((prev)=>({
//       ...prev,modulecheckbox:!prev.modulecheckbox
//     }))
//   };

//   const handleModulesChange = (selectedOptions) => {
//     const businessModulesFromURL = getBusinessModulesFromURL();
//     const updatedModules = [
//       ...businessModulesFromURL,
//       ...selectedOptions.filter(option => !businessModulesFromURL.includes(option.value)).map(option => option.value),
//     ];
//     setFormValues((prev) => ({
//       ...prev,
//       module: updatedModules,
//     }));
//   };
//   const businessModulesFromURL = getBusinessModulesFromURL();
//   return (
//     <>
//     <div className="checkbox-container d-flex align-items-center mt-2 gap-1">
//     <input
//       type="checkbox"
//       id="showBusinessModuleCheckbox"
//       checked={formValues.modulecheckbox}
//       disabled={isDisabled}
//       onChange={handleCheckboxChange}
//     />
//     <label htmlFor="showBusinessModuleCheckbox" className="combinedaddressdivlabel mb-0">Show Business Module</label>
//   </div>
//     {formValues.modulecheckbox && 
//     <Select
//       options={formValues.moduleoption}
//       value={formValues.moduleoption.filter(option => formValues.module.includes(option.value))}
//       onChange={handleModulesChange}
//       isMulti
//       isOptionDisabled={(option) => businessModulesFromURL.includes(option.value)}
//       styles={addressStyles1('36px', '#EFF3F4', '6px', '5px')} // Customize as per your design
//     />
//     }
//     </>
//   );
// };

// export default BusinessModuleSelect;

import React, {useEffect } from 'react';
import Select from 'react-select';
import { addressStyles1 } from '../../api/utils/utils';

// Helper function to extract `business_module` from the URL query parameters
const getBusinessModulesFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('business_module') ? urlParams.get('business_module').split(',') : [];
};

const BusinessModuleSelect = ({ formValues, setFormValues }) => {

  useEffect(() => {
    const businessModulesFromURL = getBusinessModulesFromURL();

    const matchedModules = businessModulesFromURL.filter(module =>
      formValues.moduleoption.some(option => option.value === module)
    );

    if (matchedModules.length > 0) {
      setFormValues((prev) => ({
        ...prev,
        module: matchedModules,
      }));
    } 
  }, [formValues.moduleoption, setFormValues]);

  const handleModulesChange = (selectedOptions) => {
    const businessModulesFromURL = getBusinessModulesFromURL();
    const updatedModules = [
      ...businessModulesFromURL,
      ...selectedOptions.filter(option => !businessModulesFromURL.includes(option.value)).map(option => option.value),
    ];
    setFormValues((prev) => ({
      ...prev,
      module: updatedModules,
    }));
  };

  const businessModulesFromURL = getBusinessModulesFromURL();

  return (
    <>
    <label htmlFor="showBusinessModuleCheckbox" className="combinedaddressdivlabel mb-0">Business Module</label>
    <Select
      inputId='showBusinessModuleCheckbox'
      options={formValues.moduleoption}
      value={formValues.moduleoption.filter(option => formValues.module.includes(option.value))}
      onChange={handleModulesChange}
      isMulti
      isOptionDisabled={(option) => businessModulesFromURL.includes(option.value)}
      styles={addressStyles1('36px', '#EFF3F4', '6px', '5px')} // Customize as per your design
    />
    </>
  );
};

export default BusinessModuleSelect;
