import React, { useState } from "react";
import Sidebar from "./sidebar";
import Navbars from "./navbar";
import './review.css';
import './parental.css';
import parentalimg from './images/parental_control.png';
import cancel from './images/cancel-01.png';
// import { useNavigate } from "react-router-dom";
// import { extractLoginUser } from "./api/utils/utils";
// import Modal from './Modal';

function Childdetails() {
  
  
    return (
        <>
            <Navbars />
            <div className="container-fluid bg-light" style={{padding:'0px'}}>
                <div className="documentrow">
                    <div className="col-12 col-md-3 col-lg-3  documentside">
                        <Sidebar />
                    </div>

                    <div className="col-12  col-md-9 col-lg-9  documentmain">
                        <div className="parentalmain">
                            <div className="parentalmainfirst">
                            <h5 className="heading">Parental Control</h5>
                            <p>Manage your General Profile, including Personal Details, Services, Contact info and Addresses</p>
                            </div>
                            <div className="parnetalmainlast">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Childdetails;