import React,{useEffect,useRef, useState} from "react";
import back from '../../../images/arrow-left-01.png';
import searchedlocationicon from '../../../images/address-icons/Group 1858.png';
import { APIProvider, Map, Marker, useMap } from '@vis.gl/react-google-maps';
import axiosInstance from "../../../api/utils/axiosutils";
import { locationstoreapi } from "../../../api/helper/urlhelper";
import { extractLoginUser,handleApiError,getQueryParameter} from "../../../api/utils/utils";
// import { useNavigate } from "react-router-dom";
import WithToast from "../../../api/hooks/withtoasthook";
import { locationstore } from "../../addresscommon/addressfunction";
import UseCustomNavigate from "../../../api/hooks/customnavigatehook";
const locationstoreapiurl = locationstoreapi();
const MapHandler = ({ place, marker }) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !place || !marker) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry.viewport);
    }

    marker.setPosition(place.geometry?.location);
  }, [map, place, marker]);

  return null;
};
function Searchedlocationstore(
   { addressComponents,
    cameraProps,
    markerPosition,
    selectedPlace,
    Change,
    back3,
    googlemapkey,
    formValues,
    setFormValues,
    showErrorToast,handleCameraChange,countryData,mobileChange
 }
){
  const {
    mccId,
  } = countryData;
    const [isLoading4,setIsLoading4]=useState(false);
    const [error422,setError422]=useState('');
    const navigate = UseCustomNavigate();
    const login_user = extractLoginUser();
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: type === 'checkbox' ? checked : value
        }));
      };
      const location_string = formValues.addressType === 'Others' ? formValues.otherType : formValues.addressType;  
    const markerRef = useRef(null);
    
    const { street, city, state, country, zipcode ,latitude,longitude,code} = addressComponents;
    const confirmAndProceed2 = async() => {
       let data = {
        block_number:formValues.houseNumber,
        // apartment_number:formValues.apartment,
        address_line_1:street,
        address_line_2:formValues.addressLine2,
        postal_code:zipcode || formValues.postcode,
        city:city || formValues.city,
        state:state,
        country:country,
        location_type:location_string,
        is_primary:formValues.default ? 1 : 0,
        latitude:latitude,
        longitude:longitude,
        first_name:formValues.Name,
        mobile:mobileChange,
        mcc_id:mccId,
        country_code:code
       }
       if(addressComponents.street){
        data.address_line_1=street
       }
       if(formValues.apartment){
        data.apartment_number=formValues.apartment
       }
       if(formValues.module){
         data.business=1;
         data.module_codes=formValues.module
       }
       const filteredData = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => value !== "" && value !== null)
      );
      await locationstore(filteredData,login_user,setIsLoading4,setError422,showErrorToast,navigate)
      //  try{
      //   setIsLoading4(true);
      //    const response = await axiosInstance.post(locationstoreapiurl,filteredData);
      //    if(response.status === 200){
      //     const redirecturl = getQueryParameter('redirecturl');
      //     if(redirecturl){
      //       window.location.href = redirecturl
      //     }else{
      //     navigate(`/u/${login_user}/profile`); 
      //     }
      //     setIsLoading4(false);
      //    }
      //  }catch(error){
      //   handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
      //  }
    }
    return(
        <>
        {isLoading4 && (
                <>   
                    <div className="loaderover">
                    <div className="loader ">
                    </div>
                    </div>
                </>
            )} 
           <div className="address_divhalf1 d-flex flex-column justify-content-between">
        <div>
          <div className="d-flex align-items-center">
            <img src={back} onClick={back3} style={{cursor:'pointer'}} className="me-2" alt="back" width="17px" height="14px" />
            <h6 className="addaddressh6">Add Address</h6>
          </div>
          <div>
            <p className="addaddressSearchp">Confirm your address</p>
            <div className='combinedaddressdiv mb-3'>
              <div className="d-flex align-items-center justify-content-between w-100">
                <div className="combinedaddressdivflex w-100">
                  <img src={searchedlocationicon} className='searchedlocationimage' alt='searchlocate' ></img>
                  <div>
                    <p className="confirmaddressp confirmaddressp1">{location_string}</p>
                    <p className="confirmaddressp2">{`${formValues.houseNumber ? formValues.houseNumber : 'dsdsa'}`}</p>
                    <p className="confirmaddressp2">{formValues.addressLine2}</p>
                    {(city || state) &&<p className="confirmaddressp2">{`${city}${state ? ` - ${state}` : ''}`},</p>}
                    {(country || zipcode) && <p className="confirmaddressp2">{`${country}${zipcode || formValues.postcode ? ` - ${zipcode || formValues.postcode}` : ''}`}</p>}
                  </div>
                </div>
                <button onClick={Change} className="combinedaddressdivflexbuuton">Change</button>
              </div>
            </div>
            <div className="default-boxdiv">
            <input type="checkbox" name="default" checked={formValues.default} onChange={handleInputChange} className="form-check-input default-box "></input>
            <label className="Defaultaddresslabel">Default address</label>
            </div>
            <div className="errors errorsaddress">{error422}</div>
          </div>
        </div>
        <button onClick={confirmAndProceed2} className="combinedaddressdivconfirmButtonprceed">Confirm & Proceed</button>
      </div>
      <div className="address_divhalf2">
        <APIProvider apiKey={googlemapkey} solutionChannel="GMP_devsite_samples_v3_rgmbasicmap">
          <Map {...cameraProps} onCameraChanged={handleCameraChange} gestureHandling="greedy">
            {markerPosition && <Marker position={markerPosition} ref={markerRef} />}
          </Map>
          <MapHandler place={selectedPlace} marker={markerRef.current} />
        </APIProvider>
      </div>
        </>
    );
}
export default WithToast(Searchedlocationstore);