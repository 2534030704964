import React,{useState,useCallback,useEffect} from 'react';
import { user_location_get } from '../../api/helper/apiurlhelper';
import { handleApiError } from '../../api/utils/utils';
import home from '../../images/address-icons/home-01.png';
import pencil from '../../images/edit-01.png';
import trash from '../../images/delete-01.png';
import friends from '../../images/address-icons/friends-01.png';
import office from '../../images/address-icons/office-01.png';
import other from '../../images/address-icons/other-01.png';
import location from '../../images/add_address-01.png';
import {deleteaddress, generatePath, getLocationTypeImage} from './addressfunction';
import UseCustomNavigate from '../../api/hooks/customnavigatehook';
function Useraddressget({showErrorToast,isInitialLoading,login_user,setFetchdata,fetchdata,setIsLoading4}) {
    const [addressline_1,setAddressline_1] = useState([]);
    const navigateTo = UseCustomNavigate(); 
    // const getLocationTypeImage = (locationType) => {
    //     switch (locationType) {
    //         case 'Home':
    //             return home;
    //         case 'Friends':
    //             return friends;
    //         case 'Office':
    //             return office;
    //         case 'Other':
    //             return other;
    //         default:
    //             return other;
    //     }
    // };
    const locationget = useCallback(async () => {
        user_location_get().then(res=>setAddressline_1(res.data.data))
        .catch(error =>  handleApiError(error, null, null, true, showErrorToast));
      }, [showErrorToast]); 
      useEffect(() => {
        if(fetchdata){
        locationget();
        setFetchdata(false);
        }
      }, [locationget]); 
    const handledeleteaddress = async(address) => {
        await deleteaddress(address,locationget, showErrorToast,setFetchdata,setIsLoading4);
    }
    const updatelocationaddress = (address,user) => {
    const path = generatePath(user, login_user, address.id);
    navigateTo(path);
    }
    const addaddress = (user) => {
    const path = generatePath(user, login_user,);
    navigateTo(path);
    }
  return (
    <>
     <div className="box border-rounded p-4 mt-3" style={{ width: '100%', minHeight: '40px', backgroundColor: 'white', border: '1px solid #ABA7A7', borderRadius: '18px' }}>
    <div className="d-flex justify-content-between addressdiv align-items-center">
        {Array.isArray(addressline_1) && addressline_1.length > 0 && (
            <h5 className='mb-4 mt-0 basicinfoh5' style={{ flex: 1 }}>Addresses</h5>
        )}
    </div>
    {Array.isArray(addressline_1) && addressline_1.length > 0 ? (
        addressline_1.map((address, index) => {
            const key = address.id ? address.id : `address-${address.address_line_1}-${address.state.name}-${address.country.name}-${address.postal_code}-${index}`;
            return (
                <div key={key}>
                    <div className="centered-column-item">
                        <div className="d-flex dashname align-items-center">
                            <div className='state'>
                                <div className='col-md-4 mb-1' style={{ flexBasis: '30%' }}>
                                    <p className='text-start basicinfoprofile'>Address {index + 1}</p>
                                </div>
                                <div className='col-md-4 mb-1 text-start' style={{ flexBasis: '30%', overflow: 'hidden' }}>
                                    <div className='d-flex align-items-baseline'>
                                        <div className='me-2'>
                                            <img className='locationimagechage' src={getLocationTypeImage(address.location_type)} alt={address.location_type} />
                                        </div>
                                        <div style={{ overflow: 'hidden' }}>
                                            <div className='d-flex align-items-center' style={{ flexWrap: 'wrap' }}>
                                                <p className='basicprofilename basicprofilename3 me-2'>{`${address.location_type}`}</p>
                                                {address.is_primary === 1 && (<button className='defaultaddresslabel'>Default Address</button>)}
                                            </div>
                                            <p className='basicprofilename basicprofilename4'>{`${address.block_number ? address.block_number.replace(/,([^,]*)$/, "$1") : ''},${address.address_line_2}${address.city.name ? ` - ${address.city.name}` : ''}`}</p>
                                            <p className='basicprofilename basicprofilename4'>{`${address.state.name}${address.country.name ? `,${address.country.name}` : ''}${address.postal_code ? ` - ${address.postal_code}` : ''}`}</p>
                                            <p className='basicprofilename basicprofilename4'>{`+${address.mcc.code} ${address.mobile}`}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 mb-1 dashnamechange text-end' style={{ flexBasis: '40%', paddingRight: '20px' }}>
                                <img onClick={() => handledeleteaddress(address)} className='me-3 marrig' style={{ cursor: 'pointer', width: '16px', height: '16px' }} src={trash} alt="delete" />
                                <img onClick={() => updatelocationaddress(address,'user')} style={{ cursor: 'pointer', width: '16px', height: '16px' }} src={pencil} alt="edit" />
                            </div>
                        </div>
                    </div>
                    <hr className='mt-0' style={{ border: '1px solid #ABA7A7', marginBottom: '25px' }} />
                </div>
            );
        })
    ) : (
        <div className="centered-column-item">
            <div className="d-flex  align-items-center">
                <div className='col-md-4 mt-0 ' style={{ flexBasis: '30%', flexShrink: 0, flexGrow: 1 }}>
                    <h5 className=' mt-0 basicinfoh5' style={{ flex: 1 }}>Addresses</h5>
                </div>
                <div className='col-md-4 mb-1 text-start ' style={{ flexBasis: '30%' }}>
                    <p className='basicprofilename  red'>Data not provided</p>
                </div>
                <div className='col-md-4 mb-1 text-end' style={{ flexBasis: '40%', flexShrink: 1, flexGrow: 1, paddingRight: '10px' }}>
                    {isInitialLoading ? '' : <button onClick={()=>addaddress('user')} style={{ width: '100%', maxWidth: '250px', height: '40px', borderRadius: '20px', border: '1px solid #CFCDCD', backgroundColor: 'white', fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#323232' }}><img src={location} className='me-2' alt='location' width='17px' height='17px' /> Add address</button>}
                </div>
            </div>
        </div>
    )}
    {Array.isArray(addressline_1) && addressline_1.length > 0 && (
        <div className='col addressbut mt-3'>
            {isInitialLoading ? '' : <button onClick={()=>addaddress('user')} style={{ width: '100%', maxWidth: '250px', height: '40px', borderRadius: '20px', border: '1px solid #CFCDCD', backgroundColor: 'white', fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#323232' }}><img src={location} className='me-2' alt='location' width='17px' height='17px' /> Add address</button>}
        </div>
    )}
</div>
    </>
  )
}

export default Useraddressget;