import React, { useCallback, useEffect, useState } from "react";
import Navbars from "../navbar";
import pencil from '../images/edit-01.png';
import logout from '../images/log-out-01.svg';
import { useNavigate } from "react-router-dom";
import plus from '../images/plus (1).png'; 
import Sidebar from "../sidebar";
import '../home.css';
import { getusersessionapi, recoverygetapi, removeusersessionapi } from "../api/helper/urlhelper";
// import axios from "axios";
import axiosInstance from "../api/utils/axiosutils";
// import { toast } from "react-toastify";
import { extractLoginUser ,handleApiError} from "../api/utils/utils";
import WithToast from "../api/hooks/withtoasthook";
const recoverygetapiurl = recoverygetapi();
const getusersessionapiurl = getusersessionapi();
const removeusersessionapiurl = removeusersessionapi();
function Passwordsecurity({showErrorToast}) {
  const login_user = extractLoginUser();
  const navigate = useNavigate();
  const [isLoading4,setIsLoading4] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [recoveryget, setRecoveryget] = useState([]);
  const [sessionget, setSessionget] = useState([]);
  const passwordchange = () => {
    navigate(`/u/${login_user}/passwordchange`);
  }
  const twofactor = () => {
    navigate(`/u/${login_user}/twofactor`);
  }
  const recoveremail = () => {
    navigate(`/u/${login_user}/recoveryemail`);
  }
  const recoveryphone = () => {
    navigate(`/u/${login_user}/recoveryphone`);
  }
  // const recoveryemailget = async () => {
  //   try {
  //     const response = await axiosInstance.post(recoverygetapiurl, {});
  //     if (response.status === 200) {
  //       const recoveryuser = response.data.data
  //       setRecoveryget(recoveryuser)
  //     }
  //   } catch (error) {
  //     handleApiError(error, null, null, true, showErrorToast)
  //   }
  //   finally{
  //     setIsInitialLoading(false);
  //   }
  // }
  const getrecoveryandsession = useCallback(async()=>{
    try{
       const [recoveryrespons,sessionrespons] = await Promise.all([
        axiosInstance.post(recoverygetapiurl),
        axiosInstance.get(getusersessionapiurl)
       ]);
       if(recoveryrespons.status === 200){
         const recovery = recoveryrespons.data.data;
         setRecoveryget(recovery)
       }
       if(sessionrespons.status === 200){
         const session = sessionrespons.data.data.values;
         setSessionget(session);
       }
    }catch(error){
      handleApiError(error, null, null, true, showErrorToast)
    }finally{
      setIsInitialLoading(false);
    }
  },[showErrorToast])
  useEffect(() => {
    getrecoveryandsession()
  }, [getrecoveryandsession]);
  const signout = async(id) =>{
    const data = {
      session_id : id
    }
    try{
      setIsLoading4(true);
      const response = await axiosInstance.post(removeusersessionapiurl,data);
      if(response.status === 200){
        await getrecoveryandsession();
        setIsLoading4(false);
      }
    }catch(error){
      handleApiError(error,setIsLoading4,null,true,showErrorToast);
    }
  }
  const isTextOverflow = recoveryget[0]?.recovery_email && recoveryget[0]?.recovery_email.length > 25;
  return (
    <>
    {isLoading4 && (
                <>   
                    <div className="loaderover">
                    <div className="loader ">
                    </div>
                    </div>
                </>
            )} 
      <Navbars />
      <div className="container-fluid bg-white passwordsecurity" style={{padding:'0px'}}>
        <div className="documentrow">
          <div className="documentside">
            <Sidebar />
          </div>
          <div className="documentmain">
            <div className="text-center ">
              <h4 className="mb-3" style={{ fontWeight: '500', fontSize: '24px', lineHeight: '36px', color: '#232323' }}>Security & Password</h4>
              <p style={{ fontWeight: '400', fontSize: '16px', lineHeight: '24px', color: '#232323' }}>Settings and password to help you keep your account secure</p>

              {/* Box container */}
              <div className="box-container p-4" style={{ width: '100%', minHeight: '335px', borderRadius: '18px', border: '1px solid #ABA7A7', backgroundColor: 'white' }}>
                <h4 className="text-start mb-3" style={{ fontWeight: '500', fontSize: '20px', lineHeight: '30px', color: '#232323' }}>Login & Recovery</h4>
                <p className="text-start" style={{ fontWeight: '400', fontSize: '14px', lineHeight: '21px', color: '#232323' }}>Manage your passwords , login preferences and recovery methods.</p>
                <div className="centered-column-item">
                  <div className="d-flex dashname mt-4 align-items-center ">
                    <div className="state">
                      <div className='col-md-4 mb-1 ' style={{ flexBasis: '30%' }}>
                        <p className='text-start' style={{ color: '#7D7878', fontWeight: '400', fontSize: '16px', lineHeight: '24px' }}>Password</p>
                      </div>

                      <div className='col-md-4 mb-1 text-start ' style={{ flexBasis: '30%' }}>
                        <p style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#323232' }}></p>
                      </div>
                    </div>
                    <div className='col-md-4 dashnamechange d-flex justify-content-end mb-1 text-end' style={{ flexBasis: '40%', }}>
                    {isInitialLoading ? " " :<button onClick={passwordchange} style={{width:'200px'}} className='dataaddbutton'>Change Password</button>}
                      {/* <button onClick={passwordchange} style={{ border: 'none', backgroundColor: 'white' }}><img style={{ width: '16px', height: '16px' }} src={pencil} alt="edit"></img></button> */}
                    </div>
                  </div>
                </div>
                <hr className='mt-0' style={{ border: '1px solid #ABA7A7', marginBottom: '0px' }} />
                <div className="centered-column-item">
                  <div className="d-flex dashname mt-4 align-items-center ">
                    <div className="state">
                      <div className='col-md-4 mb-1' style={{ flexBasis: '30%' }}>
                        <p className='text-start' style={{ color: '#7D7878', fontWeight: '400', fontSize: '16px', lineHeight: '24px' }}>Recovery email</p>
                      </div>
                      {recoveryget.length == 0 || recoveryget[0]?.recovery_email == null ? (
                        <div className='col-md-4 mb-1 text-start ' style={{ flexBasis: '30%' }}>
                          <p style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#ff000059', wordBreak: 'break-all', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Data not provided</p>
                        </div>
                      ) : (
                        <div className='col-md-4 mb-1 text-start ' style={{ flexBasis: '30%' }}>
                          <p style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#323232', wordBreak: 'break-all', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{recoveryget[0]?.recovery_email}</p>
                        </div>
                      )}
                    </div>
                    {recoveryget.length == 0 || recoveryget[0]?.recovery_email == null ? (
                      <div className='col-md-4 mb-1  d-flex justify-content-end dashnamechange' style={{ flexBasis: '40%', }}>
                       {isInitialLoading ? " " :  <button onClick={recoveremail} className='dataaddbutton'><img onClick={recoveremail} style={{ cursor: 'pointer', width: '14px', height: 'auto', marginRight: '7px' }} src={plus} alt="edit"></img>Add</button> }
                      </div>
                    ) : (
                      <div className={`col-md-4 dashnamechange mb-1 text-end ${isTextOverflow ? 'with-padding' : ''}`} style={{ flexBasis: '40%' }}>
                       {isInitialLoading ? " " : <img onClick={recoveremail} className='edit-icon' style={{ cursor: 'pointer', width: '16px', height: '16px' }} src={pencil} alt="edit" /> }
                      </div>
                    )}
                  </div>
                </div>
                <hr className='mt-0' style={{ border: '1px solid #ABA7A7', marginBottom: '0px' }} />
                <div className="centered-column-item">
                  <div className="d-flex dashname mt-4 align-items-center ">
                    <div className="state">
                      <div className='col-md-4 mb-1 ' style={{ flexBasis: '30%' }}>
                        <p className='text-start' style={{ color: '#7D7878', fontWeight: '400', fontSize: '16px', lineHeight: '24px' }}>Recovery phone</p>
                      </div>
                      {recoveryget.length == 0 || recoveryget[0].recovery_mobile == null  ? (
                        <div className='col-md-4 mb-1 text-start ' style={{ flexBasis: '30%' }}>
                          <p style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#ff000059', wordBreak: 'break-all', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Data not provided</p>
                        </div>
                      ) : (
                        <div className='col-md-4 mb-1 text-start ' style={{ flexBasis: '30%' }}>
                          <p style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#323232' }}>{`+${recoveryget[0]?.mcc.code} ${recoveryget[0]?.recovery_mobile}`}</p>
                        </div>
                      )}
                    </div>
                    {recoveryget.length == 0 || recoveryget[0].recovery_mobile == null   ? (
                      <div className='col-md-4 mb-1  d-flex justify-content-end dashnamechange' style={{ flexBasis: '40%', }}>
                        {isInitialLoading ? " " : <button onClick={recoveryphone} className='dataaddbutton'><img onClick={recoveryphone} style={{ cursor: 'pointer', width: '14px', height: 'auto', marginRight: '7px' }} src={plus} alt="edit"></img>Add</button> }
                      </div>
                    ) : (
                      <div className='col-md-4 dashnamechange mb-1 text-end' style={{ flexBasis: '40%', }}>
                      {isInitialLoading ? " " :  <button onClick={recoveryphone} style={{ border: 'none', backgroundColor: 'white' }}><img style={{ width: '16px', height: '16px' }} src={pencil} alt="edit"></img></button> }
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="box-container p-4" style={{ width: '100%', minHeight: '105px', borderRadius: '18px', border: '1px solid #ABA7A7', backgroundColor: 'white' }}>
              <div className="centered-column-item">
                  <div className="d-flex dashname mt-4 align-items-center ">
                    <div className="state">
                      <div className='col-md-4 mb-1 ' style={{ flexBasis: '40%' }}>
                        <p className='text-start' style={{ color: '#7D7878', fontWeight: '400', fontSize: '16px', lineHeight: '24px' }}>Multi-Factor authentication</p>
                      </div>
                      <div className='col-md-4 mb-1 text-start ' style={{ flexBasis: '30%' }}>
                        <p style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#323232' }}></p>
                      </div>
                    </div>
                    <div className='col-md-4 dashnamechange mb-1 d-flex justify-content-end text-end' style={{ flexBasis: '30%', }}>
                    {isInitialLoading ? " " : <button onClick={twofactor} className='dataaddbutton'><img style={{ width: '14px', height: 'auto', marginRight: '7px' }} src={pencil} alt="edit"></img>Edit</button> }
                      {/* <button onClick={twofactor} style={{ border: 'none', backgroundColor: 'white' }}><img style={{ width: '16px', height: '16px' }} src={pencil} alt="edit"></img></button> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-container" style={{ width: '100%', height: '100%', borderRadius: '18px', border: '1px solid #ABA7A7', backgroundColor: 'white', padding: '20px 30px' }}>
                <h4 className="text-start mt-3" style={{ fontWeight: '500', fontSize: '20px', lineHeight: '30px', color: '#232323' }}>Your Devices</h4>
                <p className="text-start" style={{ fontWeight: '400', fontSize: '14px', lineHeight: '21px', color: '#232323' }}>Where you're logged in </p>
                { sessionget.length > 0 &&   
                sessionget.map((item, index) => (
  <React.Fragment key={item.id}>
    <div className="centered-column-item">
      <div className="d-flex dashname mt-4 align-items-center">
        <div className="state">
          <div className="col-md-4 mb-1" style={{ flexBasis: '30%' }}>
            {/* {item.request_agent === 'WEB' } */}
            <p className="text-start" style={{ color: '#7D7878', fontWeight: '400', fontSize: '16px', lineHeight: '24px' }}>
            {item.request_agent === 'WEB' ? item.useragent.platform : item.userdevice.device_type}
            </p>
          </div>
          <div className="col-md-4 mb-1 text-start" style={{ flexBasis: '30%' }}>
            <p style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#323232' }}>
              {`${item.city.name},${item.country.name}`} {/* Replace with dynamic content if available */}
            </p>
          </div>
        </div>
        <div className="col-md-4 dashnamechange mb-1 d-flex justify-content-end" style={{ flexBasis: '40%', paddingRight: '0px' }}>
          <button style={{ width: '200px' }} className="dataaddbutton" onClick={()=>signout(item.id)}>
            <img className="me-1" onClick={()=>signout(item.id)} style={{ width: '14px', height: '14px' }} src={logout} alt="logout" /> Sign out
          </button>
          <img className="pencil-icon" style={{ display: 'none' }} src={logout} width="16px" height="16px" alt="edit" />
        </div>
      </div>
    </div>
    {index < sessionget.length - 1 && <hr />} {/* Add <hr> only between items */}
  </React.Fragment>
))}


                {/* <div className="centered-column-item">
                  <div className="d-flex dashname mt-4 align-items-center ">
                    <div className="state">
                      <div className='col-md-4 mb-1 ' style={{ flexBasis: '30%' }}>
                        <p className='text-start' style={{ color: '#7D7878', fontWeight: '400', fontSize: '16px', lineHeight: '24px' }}>Safari, iPhone 12 pro</p>
                      </div>
                      <div className='col-md-4 mb-1 text-start ' style={{ flexBasis: '30%' }}>
                        <p style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#323232' }}>Kampala, Uganda</p>
                      </div>
                    </div>
                    <div className='col-md-4 dashnamechange mb-1 d-flex justify-content-end text-end' style={{ flexBasis: '40%', paddingRight: '10px' }}>
                      <button style={{width:'200px'}} className='dataaddbutton'>
                        <img className="me-1" style={{ width: '14px', height: '14px', }} src={logout} alt="edit" /> Sign out
                      </button>
                      <img className='pencil-icon text-end' style={{ display: 'none' }} src={logout} width='16px' height='16px' alt="edit" />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="centered-column-item">
                  <div className="d-flex dashname align-items-center ">
                    <div className="state">
                      <div className='col-md-4 mb-1 ' style={{ flexBasis: '30%' }}>
                        <p className='text-start' style={{ color: '#7D7878', fontWeight: '400', fontSize: '16px', lineHeight: '24px' }}>Safari, iPhone 12 pro</p>
                      </div>
                      <div className='col-md-4 mb-1 text-start ' style={{ flexBasis: '30%' }}>
                        <p style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#323232' }}>Kampala, Uganda</p>
                      </div>
                    </div>
                    <div className='col-md-4 dashnamechange mb-1 d-flex justify-content-end text-end' style={{ flexBasis: '40%', paddingRight: '10px' }}>
                      <button style={{width:'200px'}} className='dataaddbutton'>
                        <img className="me-1" style={{ width: '14px', height: '14px', }} src={logout} alt="edit" /> Sign out
                      </button>
                      <img className='pencil-icon text-end' style={{ display: 'none' }} src={logout} width='16px' height='16px' alt="edit" />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default WithToast(Passwordsecurity);