// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parentalmainfirst h5{
    line-height: 2rem;
}
.detailsconfigurebox .detailsconfigure{
background-color: #fff;
padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/childdetails.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;AACA;AACA,sBAAsB;AACtB,aAAa;AACb","sourcesContent":[".parentalmainfirst h5{\n    line-height: 2rem;\n}\n.detailsconfigurebox .detailsconfigure{\nbackground-color: #fff;\npadding: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
