function domain()
{
    return process.env.REACT_APP_API_URL;
}
export function publicDomain()
{
    return process.env.REACT_APP_PUBLIC_URL;
}
export function accountsdomain()
{
    return process.env.REACT_APP_ACCOUNTS_URL;
}
export function Wallet()
{
    return process.env.REACT_APP_WALLET_URL;
}
export function staticimage()
{
    return process.env.REACT_APP_STATIC_PUBLIC_URL;
}
export function GoogleMapurl()
{
    return process.env.REACT_APP_GOOGLE_MAP_KEY;
}
export function sidapi() 
{
    return domain() + "/api/v1.0/app/core/auth/sid";
}
export function loginapi()
{
   return domain() + "/api/v1.0/app/core/auth/login";
}
export function ipapi(){
    return domain() + "/api/v1.0/app/core/general/ip";
}
export function logoutapi()
{
    return domain() + '/api/v1.0/app/core/auth/logout';
}
export function signupapi()
{
    return domain() + "/api/v1.0/app/core/auth/signup";
}
export function country_codeapi()
{
    return domain() + "/api/v1.0/app/core/general/mcc/list/all";
}
export function countryselectapi()
{
    return domain() + "/api/v1.0/app/core/general/country/list/all";
}
export function forgototpapi()
{
    return domain() + "/api/v1.0/app/core/auth/forgot/password";
}
export function verifyotpapi()
{
    return domain() + "/api/v1.0/app/core/auth/forgot/password/verify-otp";
}
export function resendotpapi()
{
    return domain() + '/api/v1.0/app/core/auth/resend-otp';
}
export function resetpasswordsetapi()
{
    return domain() + '/api/v1.0/app/core/auth/reset/password';
}
export function signupverifyapi()
{
    return domain() + '/api/v1.0/app/core/auth/verify-otp';
}
export function passwordverifyapi()
{
    return domain() + '/api/v1.0/app/core/user/verify/password';
}
export function profileapi()
{
    return domain() + '/api/v1.0/app/core/user/profile';
}
export function refreshapi()
{
    return domain() + '/api/v1.0/app/core/auth/refresh/token';
}
export function dateofbirthapi()
{
    return domain() + '/api/v1.0/app/core/user/profile/update';
}
export function profileremoveapi()
{
    return domain() + '/api/v1.0/app/core/user/profile/remove/image';
}
export function genderapi()
{
    return domain() + '/api/v1.0/app/core/user/profile/update';
}
export function profileupdateotpapi()
{
    return domain() + '/api/v1.0/app/core/user/profile/update/send-otp';
}
export function profileupdateauthapi()
{
    return domain() + '/api/v1.0/app/core/user/profile/update/auth';
}
export function statelistapi()
{
    return domain() + '/api/v1.0/app/core/general/state/list/all';
}
export function citylistapi()
{
    return domain() + '/api/v1.0/app/core/general/city/list/all';
}
export function locationtypesapi()
{
    return domain() + '/api/v1.0/app/core/user/location/types';
}
export function locationupdateapi()
{
    return domain() + '/api/v1.0/app/core/user/location/update';
}
export function locationstoreapi()
{
    return domain() + '/api/v1.0/app/core/user/location/store';
}
export function getuserlocationapi()
{    
    return domain() + '/api/v1.0/app/core/user/locations' 
}    
export function getuserbussinesslocationapi()
{    
    return domain() + '/api/v1.0/app/core/user/business/locations' 
}    
export function modulesproviderapi()
{
    return domain() + '/api/v1.0/app/core/user/modules/provider';
}
export function getuserdeleteapi()
{
    return domain() + '/api/v1.0/app/core/user/location/delete';
}
export function languagelistapi()
{
    return domain() + '/api/v1.0/app/core/user/language/list/all';
}
export function languagestoreapi()
{
    return domain() + '/api/v1.0/app/core/user/language/store';
}
export function languagegetapi()
{
    return domain() + '/api/v1.0/app/core/user/languages';
}
export function languageprimaryapi()
{
    return domain() + '/api/v1.0/app/core/user/language/preferred';
}
export function languageupdateapi()
{
    return domain() + '/api/v1.0/app/core/user/language/update';
}
export function updatepasswordapi()
{
    return domain() + '/api/v1.0/app/core/user/profile/update/password';
}
export function languagedeleteapi()
{
    return domain() + '/api/v1.0/app/core/user/language/delete';
}
export function otpupdatesendapi()
{
    return domain() + '/api/v1.0/app/core/auth//update/send-otp';
}
export function recoveryemailapi()
{
    return domain() + '/api/v1.0/app/core/user/profile/update/recovery';
}
export function recoverygetapi()
{
    return domain() + '/api/v1.0/app/core/user/profile/recovery';
}

//document

export function documenttypesapi()
{
    return domain() + '/api/v1.0/app/core/user/document/type';
}
export function documentstoreapi()
{
    return domain() + '/api/v1.0/app/core/user/document/store';
}
export function documentsgeneralgetapi()
{
    return domain() + '/api/v1.0/app/core/user/documents';
}
export function documentsgeneralupdateapi()
{
    return domain() + '/api/v1.0/app/core/user/document/update';
}
export function documentsgeneraldeleteapi()
{
    return domain() + '/api/v1.0/app/core/user/document/delete';
}

// vehicle apis

export function vehiclecompanyapi()
{
    return domain() + '/api/v1.0/app/core/user/vehicle/companies/all';
}
export function vechiclemodelapi()
{
    return domain() + '/api/v1.0/app/core/user/vehicle/models';
}
export function vechiclestoreapi()
{
    return domain() + '/api/v1.0/app/core/user/vehicle/store';
}
export function vechicleupdateapi()
{
    return domain() + '/api/v1.0/app/core/user/vehicle/update';
}
export function vechicledeleteapi()
{
    return domain() + '/api/v1.0/app/core/user/vehicle/delete';
}
export function vechiclegetapi()
{
    return domain() + '/api/v1.0/app/core/user/vehicles';
}
export function vechicledeleteimageapi()
{
    return domain() + '/api/v1.0/app/core/user/vehicle/image/delete';
}
export function vechicledocumentstoreapi()
{
    return domain() + '/api/v1.0/app/core/user/vehicle/document/store';
}
export function vechicledocumentupdateapi()
{
    return domain() + '/api/v1.0/app/core/user/vehicle/document/update';   
}
export function vechicledocumentdeleteapi()
{
    return domain() + '/api/v1.0/app/core/user/vehicle/document/delete';
}
export function vechicledocumentgetapi()
{
    return domain() + '/api/v1.0/app/core/user/vehicle/documents';
}


// workhistory 

export function worklocationtypeapi()
{
    return domain() + '/api/v1.0/app/core/user/experience/location-types';
}
export function workemploymenttypeapi()
{
    return domain() + '/api/v1.0/app/core/user/employment/types';
}
export function workhistorystoreapi()
{
    return domain() + '/api/v1.0/app/core/user/experience/store';
}
export function workhistorygetapi()
{
    return domain() + '/api/v1.0/app/core/user/experiences';
}
export function workhistoryupdateapi()
{
    return domain() + '/api/v1.0/app/core/user/experience/update';
}
export function workhistorydeleteapi()
{
    return domain() + '/api/v1.0/app/core/user/experience/delete';
}
export function workhistorydocumentstoreapi()
{
    return domain() + '/api/v1.0/app/core/user/experience/document/store';
}
export function workhistorydocumentgetapi()
{
    return domain() + '/api/v1.0/app/core/user/experience/documents';
}
export function workhistorydocumentupdateapi()
{
    return domain() + '/api/v1.0/app/core/user/experience/document/update';
}
export function workhistorydocumentdeleteapi()
{
    return domain() + '/api/v1.0/app/core/user/experience/document/delete';
}
// acdamic history

export function acdamiclevelapi()
{
    return domain() + '/api/v1.0/app/core/user/education/levels';
}
export function acdamicstoreapi()
{
    return domain() + '/api/v1.0/app/core/user/education/store';
}
export function acdamichistorygetapi()
{
    return domain() + '/api/v1.0/app/core/user/educations';
}
export function acdamichistoryupdateapi()
{
    return domain() + '/api/v1.0/app/core/user/education/update';
}
export function acdamichistorydeleteapi()
{
    return domain() + '/api/v1.0/app/core/user/education/delete';
}
export function acdamichistorydocumentgetapi()
{
    return domain() + '/api/v1.0/app/core/user/education/documents'
}
export function acdamichistorydocumentstoreapi()
{
    return domain() + '/api/v1.0/app/core/user/education/document/store';
}
export function acdamichistorydocumentdeleteapi()
{
    return domain() + '/api/v1.0/app/core/user/education/document/delete';
}
export function acdamichistorydocumentupdateapi()
{
    return domain() + '/api/v1.0/app/core/user/education/document/update';
}

// skillapi

export function skilllistapi()
{
    return domain() + '/api/v1.0/app/core/user/skills/list';
}
export function skillstoreapi()
{
    return domain() + '/api/v1.0/app/core/user/skill/store';
}
export function skillgetapi()
{
    return domain() + '/api/v1.0/app/core/user/skills';
}
export function skilldeleteapi()
{
    return domain() + '/api/v1.0/app/core/user/skill/delete';
}

export function storeemergencyapi()
{
    return domain() + '/api/v1.0/app/core/user/emergency/contact/store';
}
export function getemergencyapi()
{
    return domain() + '/api/v1.0/app/core/user/emergency/contacts';
}
export function deleteemergencyapi()
{
    return domain() + '/api/v1.0/app/core/user/emergency/contact/delete';
}
export function Updateemergencyapi()
{
    return domain() + '/api/v1.0/app/core/user/emergency/contact/update';
}
export function getemergencyrelationlistapi()
{
    return domain() + "/api/v1.0/app/core/user/emergency/relation";
}
export function getusersessionapi()
{
    return domain() + '/api/v1.0/app/core/user/session';
}
export function removeusersessionapi()
{
    return domain() + '/api/v1.0/app/core/user/session/remove';
}
