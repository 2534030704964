import React, { useState } from 'react';
import './SideBar.css';
import right from '../images/right.png';
import up from '../images/up_arrow_white.png';
import down from '../images/down.png';
import minor from '../images/minor.png';

const SideBar = () => {
    const [activeDropdown, setActiveDropdown] = useState(null); // Tracks the currently open dropdown
    const [selectedOption, setSelectedOption] = useState(null);

    const toggleDropDown = (dropdown) => {
        setActiveDropdown((prev) => (prev === dropdown ? null : dropdown)); // Toggle the specified dropdown
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    return (
        <div className='sideBar_main'>
            <div className='prof'>
                <img src={minor} className='minor1' width={37} alt="Profile" />
                <span className='minor1_name'>Wade Warren</span>
            </div>

            <div className=' configTab'>
                <span>Configuration Tab</span>
                <img src={down} className='down-arrow' width={18} height={18} alt="Down Arrow" />
            </div>

            <div className='client-app'>
                <button
                    className={`dropdown ${activeDropdown === 'client' ? 'dropDown1' : 'blank'}`}
                    onClick={() => toggleDropDown('client')}>
                    Client App Services
                    <img
                        src={activeDropdown === 'client' ? up : right}
                        className='arrow-icon'
                        width={15}
                        alt="Toggle Arrow"
                    />
                </button>
                <div className={`line ${activeDropdown === 'client' ? 'visible' : 'hidden'}`}></div>
                {activeDropdown === 'client' && (
                    <ul className='options'>
                        <li
                            className={selectedOption === 'EVzone Marketplace' ? 'selected' : ''}
                            onClick={() => handleOptionClick('EVzone Marketplace')}
                        >
                            EVzone Marketplace
                        </li>
                        <li
                            className={selectedOption === 'EVzone Charging' ? 'selected' : ''}
                            onClick={() => handleOptionClick('EVzone Charging')}
                        >
                            EVzone Charging
                        </li>
                        <li
                            className={selectedOption === 'EVzone Ride' ? 'selected' : ''}
                            onClick={() => handleOptionClick('EVzone Ride')}
                        >
                            EVzone Ride
                        </li>
                        <li
                            className={selectedOption === 'School' ? 'selected' : ''}
                            onClick={() => handleOptionClick('School')}
                        >
                            School
                        </li>
                        <li
                            className={selectedOption === 'Faithhub' ? 'selected' : ''}
                            onClick={() => handleOptionClick('Faithhub')}
                        >
                            Faithhub
                        </li>
                    </ul>
                )}
            </div>
            <div className='agent-app'>
                <button
                    className={`dropdown ${activeDropdown === 'agent' ? 'dropDown2' : 'blank'}`}
                    onClick={() => toggleDropDown('agent')}
                    style={{width:'100%'}}
                >
                    Agent App Services
                    <img
                        src={activeDropdown === 'agent' ? up : right}
                        className='arrow-icon'
                        width={15}
                        alt="Toggle Arrow"
                    />
                </button>
                <div className={`line ${activeDropdown === 'agent' ? 'visible' : 'hidden'}`}></div>
                {activeDropdown === 'agent' && (
                    <ul className='options'>
                        <li
                            className={selectedOption === 'School' ? 'selected' : ''}
                            onClick={() => handleOptionClick('School')}
                        >
                            School
                        </li>
                        <li
                            className={selectedOption === 'EVzone Driver' ? 'selected' : ''}
                            onClick={() => handleOptionClick('EVzone Driver')}
                        >
                            EVzone Driver
                        </li>
                        <li
                            className={selectedOption === 'EVzone Charging' ? 'selected' : ''}
                            onClick={() => handleOptionClick('EVzone Charging')}
                        >
                            EVzone Charging
                        </li>
                        <li
                            className={selectedOption === 'FaithHub' ? 'selected' : ''}
                            onClick={() => handleOptionClick('FaithHub')}
                        >
                            FaithHub
                        </li>
                        <li
                            className={selectedOption === 'EVzone Finance' ? 'selected' : ''}
                            onClick={() => handleOptionClick('EVzone Finance')}
                        >
                            EVzone Finance
                        </li>
                    </ul>
                )}
            </div>

            <div className='web-app'>
                <button
                    className={`dropdown ${activeDropdown === 'web' ? 'dropDown3' : 'blank'}`}
                    onClick={() => toggleDropDown('web')}
                    style={{width:'100%'}}
                >
                    Web Services
                    <img
                        src={activeDropdown === 'web' ? up : right}
                        className='arrow-icon'
                        width={15}
                        alt="Toggle Arrow"
                    />
                </button>
            </div>
        </div>
    );
};

export default SideBar;
