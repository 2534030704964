import './Header.css'
// import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import ev_logo from '../images/evzone(1).png'
import product from '../images/product.png';
import parent from '../images/user-circle.png';
import Button from 'react-bootstrap/Button';
import bell from '../images/smallBell.png'

function Header() {
  return (
    <>
      <Navbar className="bg-body-white-3 mt-0 pb-0 align-items-center navigation-bar">
        <div className='container-fluid bg-white'>

          <Navbar.Brand href="#" className='ms-2 mt-3 mb-2 pt-0 pb-2 logo-section'>
            <img src={ev_logo} width={180} alt='logo'/>
          </Navbar.Brand>
            
          <Nav className="justify-content-end flex-grow-1 pe-3 mt-3 mb-1">

            <Nav.Link href="" className='notify-bell'><img src={bell} alt='' /></Nav.Link>

            <Nav.Link href="" className='menu-button'><img src={product} className='product_icon' width={22} height={22} alt='' /></Nav.Link>

            <NavDropdown className='account-user' title={<> <img src={parent} width={30} alt='parent' /> <span>John Doe</span></> } align="end" menuVariant="light">

            <NavDropdown.Item href="" className=''><img className='' src={parent} width={40} alt='parent' /> <span className='me-0'>John Doe</span><br></br><span className='ms-5'>johndoe07@gmail.com</span><br /><Button className='Button acc-button' variant="outline-dark ms-5 mt-3">Account Settings</Button></NavDropdown.Item>
              
            <NavDropdown.Item href="" className=''><img className='' src={parent} width={40} alt='parent' /> <span className='me-5'>John Doe</span><br></br><span className='ms-5'>johndoe07@gmail.com</span></NavDropdown.Item></NavDropdown>
          
          </Nav>
        </div>
      </Navbar>
    </>
  );
}

export default Header;