import React, { useState, useEffect } from "react";
import axiosInstance from "../api/utils/axiosutils";
import { extractLoginUser, handleApiError,customStyles1 } from "../api/utils/utils";
import { useNavigate } from "react-router-dom";
import trash from '../images/delete-01.png';
import Navbars from "../navbar";
import leftarrow from '../images/arrow-left-01.png';
import { languagelistapi, languagestoreapi, languagegetapi, languageupdateapi, languageprimaryapi, languagedeleteapi } from "../api/helper/urlhelper";
import WithToast from "../api/hooks/withtoasthook";
import Select from 'react-select';
import Swal from 'sweetalert2';

const languagelistapiurl = languagelistapi();
const languagestoreapiurl = languagestoreapi();
const languagegetapiurl = languagegetapi();
const languageupdateapiurl = languageupdateapi();
const languageprimaryapiurl = languageprimaryapi();
const languagedeleteapiurl = languagedeleteapi();

function Languagechange({ showErrorToast }) {
  const login_user = extractLoginUser();
  const navigate = useNavigate();
  const [allLanguages, setAllLanguages] = useState([]);
  const [userLanguages, setUserLanguages] = useState([]);
  const [addLanguage, setAddLanguage] = useState([]);
  const [selectPreferredLanguage, setSelectPreferredLanguage] = useState(null);
  const [selectOtherLanguages, setSelectOtherLanguages] = useState([]);
  const [error422, setError422] = useState('');
  const [isLoading4, setIsLoading4] = useState(false);

  const back = () => {
    navigate(`/u/${login_user}/profile`);
  }

  const userLanguage = async () => {
    try {
      const response = await axiosInstance.get(languagegetapiurl);
      const languages = response.data.data;
      // setUserLanguages(languages.map(lang =>({value:lang.language_id , label:lang.name})));
      const preferredLanguage = languages.find(lang => lang.preferred === 1);
      setSelectPreferredLanguage(preferredLanguage ? { value: preferredLanguage.language_id, label: preferredLanguage.name } : null);
      const otherLanguages = languages.filter(lang => lang.preferred === 0)
                                      .map(lang => ({ value: lang.language_id, label: lang.name }));
                                      fetchAllLanguages(otherLanguages);
      setSelectOtherLanguages(otherLanguages);
    } catch (error) {
      handleApiError(error, setIsLoading4, null, true, showErrorToast);
    }
  };

  const fetchAllLanguages = async (otherLanguages) => {
    try {
      const response = await axiosInstance.get(languagelistapiurl);
      const data = response.data.data.map(lang => ({ value: lang.id, label: lang.name }));
      const otherLanguageValues = otherLanguages.map(lang => lang.value);
      const filteredData = data.filter(lang => !otherLanguageValues.includes(lang.value));
      setAllLanguages(data);
      setUserLanguages(filteredData);
    } catch (error) {
      handleApiError(error, setIsLoading4, null, true, showErrorToast);
    }
  };

  useEffect(() => {
    userLanguage();
    // fetchAllLanguages();
  }, []);

  const handlePreferredLanguageChange = (selectedOption) => {
    setSelectPreferredLanguage(selectedOption);
    preferredLanguagestore(selectedOption.value);
  };

  const preferredLanguagestore = async (selectedValue) => {
    const data = {
      language_id: selectedValue,
      is_read: 0,
      is_written: 0,
      is_spoken: 0,
      preferred: 1
    };
    try {
      setIsLoading4(true);
      const response = await axiosInstance.post(languageprimaryapiurl, data);
      if (response.status === 200) {
        userLanguage();
        setError422('');
        setIsLoading4(false);
      }
    } catch (error) {
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }
  }

  const handleOtherLanguageChange = (index, selectedOption) => {
    const newSelectOtherLanguages = [...selectOtherLanguages];
    newSelectOtherLanguages[index] = selectedOption;
    setSelectOtherLanguages(newSelectOtherLanguages);
    const userLanguageId = selectOtherLanguages[index].value;
    if(userLanguageId){
    const data = {
      user_language_id: userLanguageId,
      language_id: selectedOption.value,
      is_read: 0,
      is_written: 0,
      is_spoken: 0,
      preferred: 0
    };
    addOtherLanguagesupdate(data)
  }else{
    const data = {
      language_id: selectedOption.value,
      is_read: 0,
      is_written: 0,
      is_spoken: 0,
      preferred: 0,
    }; 
  addOtherLanguages(data)
  }  
    // if (userLanguageId) {
    //   addOtherLanguagesupdate(data);
    // } else {
    //   addOtherLanguages(data);
    // }
  };

  const addOtherLanguages = async (data) => {
    try {
      setIsLoading4(true);
      const response = await axiosInstance.post(languagestoreapiurl, data);
      if (response.status === 200) {
        userLanguage();
        setError422('');
        setIsLoading4(false)
      }
    } catch (error) {
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }
  }

  const addOtherLanguagesupdate = async (data) => {
    try {
      setIsLoading4(true);
      const response = await axiosInstance.post(languageupdateapiurl, data);
      if (response.status === 200) {
        userLanguage();
        setError422('');
        setIsLoading4(false);
      }
    } catch (error) {
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }
  }

  const renderFilteredOptions = (selectedLanguages, selectedIndex) => {
    const filteredPreferredLanguages = allLanguages.filter(lang => (
      lang.value === selectedLanguages[selectedIndex]?.value || !selectedLanguages.some(selLang => selLang.value === lang.value)
    ));
    const removePreferred = selectPreferredLanguage?.value;
    const filteredLanguages = removePreferred ? filteredPreferredLanguages.filter(lang => lang.value !== removePreferred) : filteredPreferredLanguages;
    return filteredLanguages;
  };

  const removeOtherLanguage = async (index) => {
    try {
      const shouldDelete = await Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this language!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (shouldDelete.isConfirmed) {
        const data = {
          language_id: index
        }
        setIsLoading4(true);
        const response = await axiosInstance.post(languagedeleteapiurl, data);
        if (response.status === 200) {
          userLanguage();
          setIsLoading4(false);
          setError422('');
        }
      }
    } catch (error) {
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }
  };

  const addOtherLanguage = () => {
    setSelectOtherLanguages([...selectOtherLanguages, '']);
  }

  return (
    <>
      {isLoading4 && (
        <>
          <div className="loaderover">
            <div className="loader "></div>
          </div>
        </>
      )}
      <div>
        <div className="container-fluid" style={{ height: '100px' }}>
          <Navbars />
        </div>
        <div className="name_div">
          <div className="name_div1">
            <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={back} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">Language</h5>
            </div>
            <div className="name-input">
              <div className="mb-3 ">
                <label htmlFor="preferredLanguage" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Primary Language:</label>
              </div>
              <div className="mb-3  d-flex align-items-center">
                <Select
                  value={selectPreferredLanguage}
                  onChange={handlePreferredLanguageChange}
                  options={userLanguages}
                  styles={customStyles1(Boolean(selectPreferredLanguage))}
                  placeholder="Select Primary Language"
                  className="flex-grow-1 custom-select-box"
                />
              </div>
              <div className="mb-3 mt-4">
                <hr className="border-tophr" />
              </div>
              <div className="mb-3 ">
                <label htmlFor="otherLanguage" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Other Language:</label>
              </div>
              {selectOtherLanguages.map((value, index) => (
                <div key={index}>
                  <div className="mb-3  d-flex align-items-center">
                    <Select
                      value={value}
                      onChange={(selectedOption) => handleOtherLanguageChange(index, selectedOption)}
                      options={renderFilteredOptions(selectOtherLanguages, index)}
                      styles={customStyles1(Boolean(value))}
                      placeholder="Select Language"
                      className="flex-grow-1 me-4 custom-select-box"
                    />
                    <img
                      src={trash}
                      alt="trash"
                      className="trash"
                      width="22px"
                      height="22px"
                      onClick={() => removeOtherLanguage(value.value)}
                    ></img>
                  </div>
                </div>
              ))}
              <div className="error-message">{error422}</div>
              <div className="text-left mb-4 ">
                <button type="button" onClick={addOtherLanguage} className="addlanguage">
                  Add Language
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Navbars />
      <div className="container-fluid languagepage">
        <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="col-md-7 col-sm-8 col-10">
            <div className="box p-4 " style={{ backgroundColor: 'white', width: '100%', maxWidth: '619px', minHeight: '420px', borderRadius: '18px', border: '1px solid #ABA7A7' }}>
              <div className="d-flex align-items-center mb-3 mt-4">
                <img src={leftarrow} onClick={back} alt="leftarrow" width='19.5px' height='15.5px' className="mb-3 me-4" style={{ cursor: 'pointer', marginLeft: '-10px' }}></img>
                <h5 className="mb-3" style={{ fontWeight: '500', fontSize: '24px', lineHeight: '36px', color: '#232323' }}>Language</h5>
              </div>
              <div className="mb-3 ps-5">
                <label htmlFor="preferredLanguage" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Primary Language:</label>
              </div>
              <div className="mb-3 ps-5  d-flex align-items-center">
                <select value={selectpreferredlanguages} onChange={handleSelectChange} className="form-select languageselectbox flex-grow-1 custom-select-box" id="preferredLanguage">
                  <option value="" disabled>Select Primary Language</option>
                  {userLanguages.map((language) => (
                    <option key={language.language_id} value={language.language_id}>
                      {language.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3 mt-4 ps-5">
                <hr className="border-tophr" />
              </div>
              <div className="mb-3 ps-5">
                <label htmlFor={`otherLanguage`} style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Other Language:</label>
              </div>
              {selectOtherLanguages.map((value, index) => (
                <div key={index} >
                  <div className="mb-3 ps-5 d-flex align-items-center">
                    <select
                      value={value}
                      onChange={(e) => handleOtherLanguageChange(index, e.target.value)}
                      className="form-select otherlanguagesecond flex-grow-1 me-4 custom-select-box"
                    >
                      <option value="" disabled>Select Language</option>
                      {renderFilteredOptions(selectOtherLanguages,index)}
                    </select>
                    <img
                      src={trash}
                      alt="trash"
                      className="trash"
                      width="22px"
                      height="22px"
                      onClick={() => removeotherlanguage(value)}
                    ></img>
                  </div>
                </div>
              ))}
              <div className="text-left mb-4 ps-5">
                <button type="button" onClick={addOtherLanguage} className="addlanguage" >
                  Add Language
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default WithToast(Languagechange); 
