import React from 'react'
import './ScreenTime.css'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement } from "chart.js"

ChartJS.register(CategoryScale, LinearScale, BarElement);

const ScreenTime = () => {

  const data = {
    labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    datasets: [{
      data: [4, 5, 7, 3, 4.7, 1],
      backgroundColor: 'RGB(2, 201, 139)',
      barThickness: 20,
      borderRadius: 5
    }]
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      Legend: {display: false}
    },
    layouts: {
      padding:{
        left: 0,
        right: 50
      }
    },
    scales:{
      x:{
        grid:{
          display: false, 
          drawBorder: false,
          color: 'transparent'
        },
        ticks:{
          font: { size: 12, weight: 'bold', marginTop: '' },
        }
      },

      y: {
        position: "right",
        grid: {
          drawBorder: false,
          color: "#000", 
        },
        ticks: {
          stepSize: 2,
          color: "#4a4a4a", // Label color
          font: { size: 12, weight: 'bold' },
          padding: 5,
        },
        beginAtZero: true,
        max: 8,
      },
    }
  }

  return (
    <div className='p-4 ms-0 screenTime'>
      <div className='ms-0'>
        <h6 className='mt-2 pb-3'>Average On-screen time</h6>
        <strong>5h 12m</strong>
      </div>

      <div className='mt-4 screenBarchart'>
        <Bar data={data} options={options} />
      </div>
    </div>
  )
}

export default ScreenTime