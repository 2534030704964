import { React, useState , useEffect} from "react";
import { useDispatch , useSelector } from "react-redux";
import { useNavigate} from "react-router-dom";
import eye from '../images/eye-01.png';
import eyeslash from '../images/eye_slash-01.png';
import '../home.css';
import eyegrey from '../images/eye-01pass.png';
import eyeslashgrey from '../images/eye-slash-01passw.png';
import Navbars from "../navbar";
import leftarrow from '../images/arrow-left-01.png';
import { toast } from 'react-toastify';
// import axios from "axios";
import axiosInstance from "../api/utils/axiosutils";
import { extractLoginUser,handleApiError } from "../api/utils/utils";
import { passwordverifyapi , updatepasswordapi } from "../api/helper/urlhelper";
import { fetchUserProfile } from "../api/redux/userProfiles/actions";
import WithToast from "../api/hooks/withtoasthook";
const passwordverifyapiurl = passwordverifyapi();
const updatepasswordapiurl = updatepasswordapi();
function Passworchange({showErrorToast}) {
  const login_user = extractLoginUser();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userProfile.userProfile); // Use useSelector to access userProfile from Redux state
  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error422, setError422] = useState('');
  const [isLoading4, setIsLoading4] = useState(false);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [showPasswordchange , setShowPasswordchange] = useState(true);
  const [showPasswordcurrent , setShowPasswordcurrent] = useState(false);
  const [password , setPassword] = useState('');
  const [showPasswordnew , setShowPasswordnew] = useState(false);
  const [showPasswordcur , setShowPasswordcur] = useState(false);
  const [passwordnew , setPasswordnew] = useState('');
  const [passwordcurrent , setPasswordcurrent] = useState('');
  const [isSaveButtonEnablednextotp, setIsSaveButtonEnablednextotp] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const toggleVisibility = () => {
    setShowPassword(!showPassword);
  }
  const goBack = () => {
    navigate(`/u/${login_user}/security`);
  }
  const cancelPasswordChange = () => {
    navigate(`/u/${login_user}/security`);
  }
  const check = () => {
    const valid = (
      password !== ''
    )
    setIsSaveButtonEnabled(valid)
  }
  useEffect(()=>{
    check();
  },[password])
  const goToNextStep = async() => {
  //   const token = localStorage.getItem('access_token');
  //   const headers = {
  //     'Authorization': `Bearer ${token}`,
  //     'Content-Type': 'application/json',
  // };
    const data = {
      password : password,
    }
    try{
      setIsLoading4(true);
    const response = await axiosInstance.post(passwordverifyapiurl,data);
    if (response.status === 200) {
      setIsLoading4(false);
      const password_verify = response.data.data.password_verify;
      localStorage.setItem('password_verify', password_verify);
     setShowPasswordchange(false);
     setShowPasswordcurrent(true);
    
  }
    }
    catch(error){
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }
  }
  const [isInvalid, setIsInvalid] = useState({
    letter: true,
    capital: true,
    special: true,
    number: true,
    length: true,
});
const Passwordsnew = (event) => {
    const newPasswords = event.target.value;
    setPasswordnew(newPasswords);
    const lowercaseLetters = /[a-z]/g;
    const uppercaseLetters = /[A-Z]/g;
    const specialCharacters = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-|=]/g;
    const numbers = /[0-9]/g;
    setIsInvalid({
        letter: !newPasswords.match(lowercaseLetters),
        capital: !newPasswords.match(uppercaseLetters),
        special: !newPasswords.match(specialCharacters),
        number: !newPasswords.match(numbers),
        length: newPasswords.length < 8,

    });
}
const handleFocus = () => {
    setIsFocused(true);
};
const getPasswordValidationClass = (isValid) => {
    return isValid ? 'valid' : 'invalid';
};
const handleBlur = () => {
    setIsFocused(false);
};
  const back = () => {
       setShowPasswordchange(true);
       setShowPasswordcurrent(false);
  }
  const passwordcancel = () => {
    setShowPasswordchange(true);
    setShowPasswordcurrent(false);
  }
  const togglePasswordVisibilitynew = () => {
    setShowPasswordnew(!showPasswordnew);
}
const togglePasswordVisibilitycur = () => {
  setShowPasswordcur(!showPasswordcur);
}
const checks = () => {
  const valid = (
    passwordnew !== '' && passwordcurrent !== ''
  )
  setIsSaveButtonEnablednextotp(valid);
}
useEffect(()=>{
  checks();
},[passwordnew,passwordcurrent]);
const passwordconfirm = async() => {
  // const token = localStorage.getItem('access_token');
  //   const headers = {
  //     'Authorization': `Bearer ${token}`,
  //     'Content-Type': 'application/json',
  // };
  const password_verify_token = localStorage.getItem('password_verify')
    const data = {
      new_password : passwordnew,
      new_confirm_password : passwordcurrent,
      password_verify_token : password_verify_token
    }
    try{
      setIsLoading4(true);
    const response = await axiosInstance.post(updatepasswordapiurl,data);
    if (response.status === 200) {
     setShowPasswordchange(false);
     setShowPasswordcurrent(false);
     setIsLoading4(false);
     navigate('/')
  }
    }
    catch(error){
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }
}

  return (
    <>
    {isLoading4 && (
        <>
          <div className="loaderover">
            <div className="loader "></div>
          </div>
        </>
      )}
       <div>
        <div className="container-fluid" style={{ height: '100px' }}>
          <Navbars />
        </div>
        <div className="name_div">
        <div className="name_div1">
        {showPasswordchange && (
          <>
            <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={goBack} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">Password</h5>
            </div>
            <div className="name-input name-inputimage">
            {/* <p style={{ fontWeight: '500', fontSize: '16px', lineHeight: '30px', color: '#292929' }}>To modify Mobile, Kindly enter your password</p> */}
            <div className="header-profile-user border-dark user mx-auto " style={{ width: '80px', height: '80px', borderRadius: '50%', overflow: 'hidden' }}>
            <img id="login_img_for_profile" src={userProfile?.image} alt="Profile" loading="lazy"className=" homeprofile mb-3" style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}/></div>
            <p style={{ fontWeight: '500', fontSize: '20px', lineHeight: '30px', color: '#292929' }}>{userProfile?.name}</p>
            <div className="text-start" style={{width:'100%'}}>
                <label htmlFor="password" className="mb-2" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Current Password</label>
              <div className="password " style={{ position: 'relative' }}>
                <input type={showPassword ? 'text' : 'password'} value={password} onChange={(e)=>{setPassword(e.target.value);setError422('')}} className={`form-control docform ${password ? 'docform-active' : ''}`}  id="userpassword" placeholder="Enter Your Current Password" name="password"/>
                <p className="toggle-password" onClick={toggleVisibility} style={{ position: 'absolute', marginRight: '15px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                  {showPassword ? (
                    password ? (
                    <img src={eye} className='eye' width='19.5px' height='19.5px' alt="Show Password"/>
                    ) : (
                      <img src={eyegrey} className='eye' width='19.5px' height='19.5px' alt="Show Password"/>
                    )
                  ) : (
                    password ? (
                    <img src={eyeslash} className='eye' width='19.5px' height='19.5px' alt="Hide Password" />
                    ) : (
                      <img src={eyeslashgrey} className='eye' width='19.5px' height='19.5px' alt="Hide Password" />
                    )
                  )}
                </p>
              </div>
              </div>
              <div className="error-message error-messageprofile">{error422}</div>
              <div className="name_button_div" style={{width:'100%'}}>
                <button type="button" onClick={cancelPasswordChange} className="profilecancel me-4">
                  Cancel
                </button>
                <button type="button" onClick={goToNextStep} style={{ backgroundColor: isSaveButtonEnabled ? '#02CD8D' : '#EAEAEA', color: isSaveButtonEnabled ? '#FFF' : '#333' , cursor: isSaveButtonEnabled ? 'pointer' : 'not-allowed' }} disabled={!isSaveButtonEnabled} className={`profilesave`}>
                  Next
                </button>
              </div>
            </div>
            </>
           )}
           {showPasswordcurrent &&(
            <div>
            <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={back} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">Password</h5>
            </div>
            <div className="name-input ">
            <div className="d-flex justify-content-start mb-3 ">
                                <label htmlFor="new-password" style={{ fontWeight: '500',fontSize:'16px',lineHeight:'24px',color:'#000000' }}>New Password</label>
                          </div>
                            <div className=" mb-4 " style={{position:'relative'}}>
                                <input type={showPasswordnew ? 'text' : 'password'} value={passwordnew} onFocus={handleFocus} onBlur={handleBlur}  onChange={Passwordsnew} className={`form-control docform ${passwordnew ? 'docform-active' : ''}`} id="userpassword" 
                                    placeholder="Enter New Password" name="password"  />
                                    {isFocused && (
                                    <div id="message4" className={`password-message ${isFocused ? "initial-focus" : ""}`}>
                                        <h6>Password must contain the following:</h6>
                                        <p className={getPasswordValidationClass(isInvalid.letter)}>
                                            At least a Lowercase
                                        </p>
                                        <p className={getPasswordValidationClass(isInvalid.capital)}>
                                            At least a Capital (Uppercase)
                                        </p>
                                        <p className={getPasswordValidationClass(isInvalid.special)}>
                                            At least Special Character
                                        </p>
                                        <p className={getPasswordValidationClass(isInvalid.number)}>A number</p>
                                        <p className={getPasswordValidationClass(isInvalid.length)}>
                                            Minimum 8 Characters
                                        </p>
                                    </div>
                                )}
                                <p className="toggle-password" onClick={togglePasswordVisibilitynew}style={{ position: 'absolute', marginRight: '10px', top: '0%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                    {showPasswordnew ? (
                                      passwordnew ? (
                                       <img src={eye} className='eye'style={{width:'20px',height:'auto'}} alt="Show Password" />
                                      ) : (
                                       <img src={eyegrey} className='eye'style={{width:'20px',height:'auto'}} alt="Show Password" />
                                      )
                                    ) : (
                                      passwordnew ? (
                                      <img src={eyeslash} className='eye' style={{width:'20px',height:'auto'}} alt="Hide Password" />
                                      ) : (
                                        <img src={eyeslashgrey} className='eye' style={{width:'20px',height:'auto'}} alt="Hide Password" />
                                      )
                                    )}
                                </p>
                            </div>
                            <div className="d-flex justify-content-start mb-3 ">
                                <label htmlFor="new-password" style={{ fontWeight: '500',fontSize:'16px',lineHeight:'24px',color:'#000000' }}>Confirm Password</label>
                            </div>
                            <div className="mb-4 " style={{position:'relative'}}>
                                <input type={showPasswordcur ? 'text' : 'password'} value={passwordcurrent} onChange={(e)=>setPasswordcurrent(e.target.value)} className={`form-control docform ${passwordcurrent ? 'docform-active' : ''}`} id="userpassword"
                                    placeholder="Enter Confirm Password" name="password"  />
                                <p className="toggle-password" onClick={togglePasswordVisibilitycur}style={{ position: 'absolute', marginRight: '10px', top: '0%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                    {showPasswordcur ? (
                                      passwordcurrent ? (
                                      <img src={eye} className='eye' style={{width:'20px',height:'auto'}} alt="Show Password" />
                                      ) : (
                                        <img src={eyegrey} className='eye' style={{width:'20px',height:'auto'}} alt="Show Password" />
                                      )
                                    ) : (
                                      passwordcurrent ? (
                                      <img src={eyeslash} className='eye' style={{width:'20px',height:'auto'}} alt="Hide Password" />
                                      ) : (
                                      <img src={eyeslashgrey} className='eye' style={{width:'20px',height:'auto'}} alt="Hide Password" />
                                      )
                                    )}
                                </p>
                            </div>
                            <div className="error-message error-messageprofile">{error422}</div>
                            <div className="name_button_div">
                <button type="button" onClick={passwordcancel} className="me-3  profilecancel">
                  Cancel
                </button>
                <button type="button" onClick={passwordconfirm} style={{ backgroundColor: isSaveButtonEnablednextotp ? '#02CD8D' : '#EAEAEA', color: isSaveButtonEnablednextotp ? '#FFF' : '#333' , cursor: isSaveButtonEnablednextotp ? 'pointer' : 'not-allowed' }} disabled={!isSaveButtonEnablednextotp} className={`profilesave`}>
                  Confirm
                </button>
              </div>
                            {/* <div className="d-flex  justify-content-end align-items-center">
                                <button onClick={passwordcancel} type="button" className="profilecancel  me-2">Cancel</button>
                                <button onClick={passwordconfirm} type="button" className=" profilesave">Confirm</button>
                            </div> */}
                            </div>
                            </div>
           )}
          </div>
        </div>
        </div>
      {/* <Navbars />
      {showPasswordchange && (
      <div className="container-fluid passwordchange">
        <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="col-lg-7 col-md-8 col-sm-10">
            <div className="box p-5" style={{ backgroundColor: 'white', width: '100%', maxWidth: '620px', minHeight: '424px', borderRadius: '18px', border: '1px solid #ABA7A7' }}>
              <div className="d-flex align-items-center">
                <img onClick={goBack} src={leftarrow} alt="leftarrow" width='19.5px' height='15.5px' className="mb-3 me-4" style={{ cursor: 'pointer' }} />
                <h5 className='mb-3' style={{ fontWeight: '500', fontSize: '24px', lineHeight: '36px', color: '#232323' }}>Password</h5>
              </div>
              <div className="d-flex flex-column align-items-center">
                <img src={userProfile.image} className="rounded-circle header-profile-user border-dark user img-fluid mx-auto d-block mb-3" style={{ maxWidth: '80px', height: '80px' }} alt="Right 3" />
                <p style={{ fontWeight: '500', fontSize: '20px', lineHeight: '30px', color: '#292929' }}>{userProfile.name}</p>
              </div>
              <div className="d-flex justify-content-start mb-3 ps-5">
                <label htmlFor="password" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Current Password</label>
              </div>
              <div className="password mb-3 ps-5" style={{ position: 'relative' }}>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control passwordchangeinputbox"
                  id="userpasswords"
                  value={password}
                  onChange={(e)=>setPassword(e.target.value)} 
                  placeholder="Enter Your Current Password"
                  name="password"
                />
                <p className="toggle-password" onClick={toggleVisibility} style={{ position: 'absolute', marginRight: '15px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                  {showPassword ? (
                    <img src={eye} className='eye' width='19.5px' height='19.5px' alt="Show Password"/>
                  ) : (
                    <img src={eyeslash} className='eye' width='19.5px' height='19.5px' alt="Hide Password" />
                  )}
                </p>
              </div>
                  <div className="d-flex align-items-center justify-content-end  mx-md-2 d-block">
                    <button onClick={cancelPasswordChange} type="button" className=" profilecancel me-4">Cancel</button>
                    <button onClick={goToNextStep} type="button" className=" profilesave">Next</button>
                  </div>
            </div>
          </div>
        </div>
      </div>
      )}
      {showPasswordcurrent && (
       <div className="container-fluid passwordcurrent">
                <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <div className="col-lg-7 col-md-8 col-sm-10">
                        <div className="box passwordchangecurrent" style={{ backgroundColor: 'white',width:'100%',maxWidth:'620px',minHeight:'424px',border:'1px solid #ABA7A7',borderRadius:'18px',padding:'30px 50px' }}>
                            <div className="d-flex mt-3 align-items-center">
                            <img onClick={back} src={leftarrow} alt="leftarrow" width='19.5px' height='15.5px' className="mb-3 me-4"style={{cursor:'pointer'}}></img>
                                <h5 className=' mb-3' style={{fontWeight:'500',fontSize:'24px',lineHeight:'36px',color:'#232323'}}>Password</h5>
                            </div>
                            <div className="d-flex justify-content-start mb-3 ps-5">
                                <label htmlFor="new-password" style={{ fontWeight: '500',fontSize:'16px',lineHeight:'24px',color:'#000000' }}>New Password</label>
                            </div>
                            <div className=" mb-4 ps-5" style={{position:'relative'}}>
                                <input type={showPasswordnew ? 'text' : 'password'} value={passwordnew} onFocus={handleFocus} onBlur={handleBlur}  onChange={Passwordsnew} className="form-control passwordchangeinputbox" id="userpassword"
                                    placeholder="Enter New Password" name="password"  />
                                    {isFocused && (
                                    <div id="message4" className={`password-message ${isFocused ? "initial-focus" : ""}`}>
                                        <h6>Password must contain the following:</h6>
                                        <p className={getPasswordValidationClass(isInvalid.letter)}>
                                            At least a Lowercase
                                        </p>
                                        <p className={getPasswordValidationClass(isInvalid.capital)}>
                                            At least a Capital (Uppercase)
                                        </p>
                                        <p className={getPasswordValidationClass(isInvalid.special)}>
                                            At least Special Character
                                        </p>
                                        <p className={getPasswordValidationClass(isInvalid.number)}>A number</p>
                                        <p className={getPasswordValidationClass(isInvalid.length)}>
                                            Minimum 8 Characters
                                        </p>
                                    </div>
                                )}
                                <p className="toggle-password" onClick={togglePasswordVisibilitynew}style={{ position: 'absolute', marginRight: '10px', top: '0%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                    {showPasswordnew ? (
                                       <img src={eye} className='eye'style={{width:'20px',height:'auto'}} alt="Show Password" />
                                    ) : (
                                      <img src={eyeslash} className='eye' style={{width:'20px',height:'auto'}} alt="Hide Password" />
                                    )}
                                </p>
                            </div>
                            
                            <div className="d-flex justify-content-start mb-3 ps-5">
                                <label htmlFor="new-password" style={{ fontWeight: '500',fontSize:'16px',lineHeight:'24px',color:'#000000' }}>Confirm Password</label>
                            </div>
                            <div className="mb-4 ps-5" style={{position:'relative'}}>
                                <input type={showPasswordcur ? 'text' : 'password'} value={passwordcurrent} onChange={(e)=>setPasswordcurrent(e.target.value)} className="form-control passwordchangeinputbox" id="userpassword"
                                    placeholder="Enter Confirm Password" name="password"  />
                                <p className="toggle-password" onClick={togglePasswordVisibilitycur}style={{ position: 'absolute', marginRight: '10px', top: '0%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                                    {showPasswordcur ? (
                                      <img src={eye} className='eye' style={{width:'20px',height:'auto'}} alt="Show Password" />
                                    ) : (
                                      <img src={eyeslash} className='eye' style={{width:'20px',height:'auto'}} alt="Hide Password" />
                                    )}
                                </p>
                            </div>
                            <div className="d-flex mt-5 mb-0 justify-content-end align-items-center">
                                <button onClick={passwordcancel} type="button" className="profilecancel  me-2">Cancel</button>
                                <button onClick={passwordconfirm} type="button" className=" profilesave">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )} */}
    </>
  );
}
export default WithToast(Passworchange);