import { user_location_delete, user_location_store, user_location_update,} from "../../api/helper/apiurlhelper";
import { confirmDialog, getQueryParameter, handleApiError} from "../../api/utils/utils";
import friends from '../../images/address-icons/friends-01.png';
import office from '../../images/address-icons/office-01.png';
import other from '../../images/address-icons/other-01.png';
import home from '../../images/address-icons/home-01.png';
export const deleteaddress = async (address,locationget,showErrorToast,setFetchdata,setIsLoading4) => {
    const data = {
        id: address.id
    };

    try {
        const result = await confirmDialog(
            'Delete Address?',
            'Are you sure you want to delete this address?'
        );

        if (result.isConfirmed) {
            try{
                setIsLoading4(true);
             const response = await user_location_delete(data)
             if(response.status === 200){
                await locationget();
             }
            }catch(error){
                handleApiError(error, null, null, true, showErrorToast);
            }
        }
    } catch (error) {
        handleApiError(error, null, null, true, showErrorToast);
    }finally{
        setFetchdata(false);
        setIsLoading4(false);
    }
};
export const generatePath = (userType, loginUser, id = null) => {
    const basePath = `/u/${loginUser}`;
    if (userType === 'user') {
        return id ? `${basePath}/update/${id}/address` : `${basePath}/addaddress`;
    } else {
        return id ? `${basePath}/bussiness/update/${id}/address` : `${basePath}/bussiness/address`;
    }
};

export const locationstore = async(data,login_user,setIsLoading4,setError422,showErrorToast,navigate) => {
   try{
    setIsLoading4(true);
    const response =  await user_location_store(data);
    if(response.status === 200){
        const redirecturl = getQueryParameter('redirecturl');
          if(redirecturl){;
            window.location.href = redirecturl
          }else{ 
            navigate(`/u/${login_user}/profile`); 
          }
    }
   }catch(error){
    handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
   }finally{
    setIsLoading4(false);
   }
}

export const locationupdate = async(data,login_user,setIsLoading4,setError422,showErrorToast,navigate) => {
    try{
        setIsLoading4(true);
        const response =  await user_location_update(data);
        if(response.status === 200){
            const redirecturl = getQueryParameter('redirecturl');
              if(redirecturl){;
                window.location.href = redirecturl
              }else{ 
                navigate(`/u/${login_user}/profile`); 
              }
        }
       }catch(error){
        handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
       }finally{
        setIsLoading4(false);
       }
}

export const getLocationTypeImage = (locationType) => {
    switch (locationType) {
        case 'Home':
            return home;
        case 'Friends':
            return friends;
        case 'Office':
            return office;
        case 'Other':
            return other;
        default:
            return other;
    }
};