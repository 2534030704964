import React from "react";
import { useNavigate } from "react-router-dom";
import Navbars from "./navbar";
import leftarrow from './images/arrow-left-01.png';
import { extractLoginUser } from "./api/utils/utils";
function Twofactoranother() {
  const navigate = useNavigate();
  const login_user = extractLoginUser();
  const back = () => {
    navigate(`/u/${login_user}/twofactortext`);
  }
  const cancel = () => {
    navigate(`/u/${login_user}/twofactortext`);
  }
  const next = () => {
    navigate(`/u/${login_user}/twofactorconfirm`);
  }
  return (
    <>
      <Navbars />
      <div className="container-fluid twofactoremail">
        <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="col-md-7 col-sm-8 col-10">
            <div className="box" style={{width:'100%',maxWidth:'620px',minHeight:'305px',backgroundColor:'white',borderRadius:'18px',border:'1px solid #ABA7A7',padding:'40px 35px'}}>
              <div className="d-flex align-items-center mb-3" style={{marginLeft:'10px'}}>
              <img onClick={back} src={leftarrow} alt="leftarrow" width='19.5px' height='15.5px' className="mb-3 me-4"style={{cursor:'pointer'}}></img>
                <h5 className="mb-3" style={{fontWeight:'500',fontSize:'24px',lineHeight:'36px',color:'#232323'}}>Add another Number</h5>
              </div>
              <div className="mb-3 name-input me-4 mb-4 ">
                <label className="mb-2" style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#000000'}}>Enter Phone Number</label>
                <input type="text" className="form-control tewofactoremail" placeholder="+256 782 521480" />
              </div>
              <div className="d-flex justify-content-end mx-4">
                <button type="button" onClick={cancel} className=" profilecancel me-4">
                  Cancel
                </button>
                <button onClick={next} type="button" className=" profilesave">
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Twofactoranother;